import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import Page from "./Page";
import { MemberManagement } from "./MemberManagement/Main";

export default () => {
  const restaurant_id = sessionStorage.getItem("restaurant_id");
  if (!restaurant_id) {
    const location = useLocation();
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Page title={`管理會員`}>
      <MemberManagement restaurant_id={restaurant_id} />
    </Page>
  );
};
