import React from 'react'; 

import {
  Table,
} from "antd";

import moment from "moment";
import ColorHash from "color-hash";

import { TimedCounts } from "../types/api";

export type TimedCountsTableProps = {
  data: TimedCounts;
};

export const TimedCountsTable = (props: TimedCountsTableProps) => {
  // Group by keys, then by timestamp.
  let timestamps: string[] = [];
  let rowMap: Map<string, any> = new Map<string, any>();;
  Object.entries(props.data).forEach(([ts, counts]) => {
    timestamps.push(ts);
    Object.entries(counts).forEach(([key, v]) => {
      let row = rowMap.get(key);
      if (row === undefined) {
        row = {};
        rowMap.set(key, row);
      }
      row[ts] = v;
    });
  });

  let rows: any = [];
  for (let [key, m] of rowMap) {
    const row = { ... m };
    row["xxx_tr_row_key"] = key;
    rows.push(row);
  }

  const columns: any = [
    {
      title: "Name",
      dataIndex: "xxx_tr_row_key",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      sorter: (a: any, b: any) => {
        return a["xxx_tr_row_key"].localeCompare(b["xxx_tr_row_key"]);
      },
    },
  ];

  // Add a column for each timestamp.
  timestamps.sort();
  timestamps.forEach((ts: string) => {
    columns.push({
      title: moment(ts).format("MM/DD"),
      dataIndex: ts,
      defaultSortOrder: "descend",
      sortDirections: ["ascend", "descend"],
      sorter: (a: any, b: any) => {
        if (a[ts] < b[ts]) {
          return -1;
        } else if (a[ts] === b[ts]) {
          return 0;
        }
        return 1;
      },
    });
  });

  return (
    <div>
      <Table columns={columns} dataSource={rows} />
    </div>
  );
};
