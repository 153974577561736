import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Empty,
  Modal,
  Row,
  Progress,
  notification,
} from "antd";
import { StarOutlined, StarTwoTone } from "@ant-design/icons";
import { Map, Set } from "immutable";

import ButtonWithLoading from "./ButtonWithLoading";
import PrintButton from "./PrintButton";
import ReprintItemLabelButton from "./ReprintItemLabelButton";
import PrintNonLabelItemButton from "./PrintNonLabelItemButton";
import StationCol from "./StationCol";
import { printOnOrderDone } from "./Print";

import {
  postFetch,
  postFetchEmptyBody,
  sortItems,
  API_URL,
  STAR_PRIORITY,
  STATUS_DISPLAY,
} from "./Util";
import styles from "./css/StartedOrderCol.module.css";

export default ({
  orders,
  renderOrder,
  renderOrderTitle,
  restaurantID,
  showItemSelect,
}) => {
  // Selected item attribute ID
  const [selectedItem, setSelectedItem] = useState(null);

  let itemMap = Map();
  orders.forEach((order) => {
    if (order.items) {
      order.items.forEach((item) => {
        const collapsedItemID = item.attributes_id;
        if (!itemMap.has(collapsedItemID)) {
          itemMap = itemMap.set(collapsedItemID, {
            item: item,
            orders: Set(),
          });
        }
        const matchingCollapsedItem = itemMap.get(collapsedItemID);
        matchingCollapsedItem.orders = matchingCollapsedItem.orders.add(
          order.id
        );
      });
    }
  });
  let items = itemMap
    .valueSeq()
    .toArray()
    .map((itemValue) => itemValue.item);
  items = sortItems(items);

  const renderStartedOrder = (order) => (
    <div>
      {renderOrder(order, order.items ? order.items.length : 0)}
      <Progress
        size="small"
        percent={order.percDoneActions}
        strokeColor="#52CC00"
        showInfo={false}
      />
    </div>
  );

  const renderStartedOrderDetails = (order) => {
    const items = sortItems(order.items);
    return (
      <div className={styles.orderDetails}>
        {(!items || items.length === 0 || !order.pos_order_name) && (
          <Alert
            message="這個單好像不完整。Clover可能有問題。"
            type="warning"
            showIcon
            className={styles.warning}
          />
        )}
        <div className={styles.notesAndPrint}>
          <div className={styles.orderNotes}>{order.notes ? order.notes : ""}</div>
          <PrintButton
            key="print"
            className={styles.printButton}
            handleError={(err) => {
              notification['error']({
                message: "無法列印",
                description: "請稍後再試",
              });
            }}
            order={order}
          >
            列印
          </PrintButton>
        </div>
        <div>
          <PrintNonLabelItemButton
            key="print-all-items"
            className={styles.printNonLabelItemButton}
            handleError={(err) => {
              notification['error']({
                message: "無法列印",
                description: "請稍後再試",
              });
            }}
            order={order}
          >
            列印非飲料標籤
          </PrintNonLabelItemButton>
        </div>
        <Progress
          className={styles.orderDetailsProgress}
          size="small"
          percent={order.percDoneActions}
          strokeColor="#52CC00"
          showInfo={false}
        />
        {items &&
          items.map((item, index) => {
            const itemActionStatus = order.itemActionStatuses.get(item.id);

            let checkboxClassName = `${styles.item} ${styles.startedOrderItem}`;
            let itemClassName = styles.itemWithCheckbox;
            if (index === items.length - 1) {
              checkboxClassName = `${checkboxClassName} ${styles.lastItem}`;
            }
            if (!item.actions) {
              itemClassName = `${itemClassName} ${styles.noActionItem}`;
            }

            return (
              <Row>
                <Col span={20}>
                  <Checkbox
                    key={item.id}
                    className={checkboxClassName}
                    checked={!!item.done_time}
                    onChange={(e) => {
                      let url = "";
                      if (e.target.checked) {
                        url = `${API_URL}/v1/r/${restaurantID}/items/${item.id}/done`;
                      } else {
                        url = `${API_URL}/v1/r/${restaurantID}/items/${item.id}/undo_done`;
                      }
                      postFetchEmptyBody(url, () => {
                        notification['error']({
                          message: "發生錯誤",
                          description: "請稍後再試",
                        });
                      });
                    }}
                  >
                    <div className={itemClassName}>
                      <span>
                        {item.attributes.alternate_name
                          ? item.attributes.alternate_name
                          : item.attributes.name}
                      </span>
                      <span>
                        {" "}
                        -{" "}
                        <span className={styles[`itemStatus${itemActionStatus}`]}>
                          {STATUS_DISPLAY[itemActionStatus]}
                        </span>
                      </span>
                      <div className={styles.itemNotes}>
                        {item.attributes.notes && (
                          <div>{item.attributes.notes}</div>
                        )}
                        {item.attributes.modifications && (
                          <div>{item.attributes.modifications.join(", ")}</div>
                        )}
                      </div>
                    </div>
                  </Checkbox>
                </Col>
                <Col span={4}>
                  {
                    item.print_label && (
                      <ReprintItemLabelButton
                        key={`print-item-label-${item.id}`}
                        className={styles.printButton}
                        handleError={(err) => {
                          notification['error']({
                            message: "無法重印標籤",
                            description: "請稍後再試",
                          });
                        }}
                        order={order}
                        item={item}
                      >
                        重印標籤
                      </ReprintItemLabelButton>
                    )
                  }
                </Col>
              </Row>
            );
          })}
      </div>
    );
  };

  const renderDoneButton = (order, className, style) => (
    <ButtonWithLoading
      key="done"
      className={className}
      style={style}
      postURL={`${API_URL}/v1/r/${restaurantID}/orders/${order.id}/done`}
      postHandleResponse={() => {
        printOnOrderDone(order, (err) => {
          notification['error']({
            message: "無法列印點數",
            description: "請稍後再試",
          });
        });
      }}
      postHandleError={() => {
        notification['error']({
          message: "發生錯誤",
          description: "請稍後再試",
        });
      }}
    >
      完成
    </ButtonWithLoading>
  );

  const heading = "進行中";

  const renderOrderCol = (
    elems,
    showHeading,
    colCardPadding,
    elemsMaxHeight
  ) => (
    <StationCol
      elems={elems}
      emptyDescription="No orders"
      heading={heading}
      showHeading={showHeading}
      bgColor="rgba(0, 79, 172, 0.5)"
      colCardPadding={colCardPadding}
      elemsMaxHeight={elemsMaxHeight}
      renderLeftButton={(orderID, orderIndex) =>
        orders[orderIndex].priority === STAR_PRIORITY ? (
          <StarTwoTone
            className={styles.star}
            twoToneColor="#ffc300"
            onClick={() => {
              postFetch(
                `${API_URL}/v1/r/${restaurantID}/orders/${orderID}/priority/reset`,
                {},
                null,
                () => {
                  notification['error']({
                    message: "發生錯誤",
                    description: "請稍後再試",
                  });
                }
              );
            }}
          />
        ) : (
          <StarOutlined
            className={styles.star}
            onClick={() => {
              postFetch(
                `${API_URL}/v1/r/${restaurantID}/orders/${orderID}/priority`,
                {
                  priority: STAR_PRIORITY,
                },
                null,
                () => {
                  notification['error']({
                    message: "發生錯誤",
                    description: "請稍後再試",
                  });
                }
              );
            }}
          />
        )
      }
      renderRightButton={(order, orderIndex) =>
        renderDoneButton(order, styles.doneButton, {
          height: "inherit",
          fontSize: 24,
        })
      }
      renderElem={renderStartedOrder}
      renderModal={({
        elem,
        visible,
        onCancel,
        footer,
        style,
        wrapClassName,
      }) => {
        return (
          <Modal
            title={renderOrderTitle ? renderOrderTitle(elem) : null}
            visible={visible}
            destroyOnClose={
              /* to recheck the printer status when modal reopens */ true
            }
            onCancel={onCancel}
            footer={
              <div className={styles.modalFooter}>
                <ButtonWithLoading
                  key="undo"
                  className={styles.undoButton}
                  postURL={`${API_URL}/v1/r/${restaurantID}/orders/${elem.id}/undo`}
                  postHandleError={() => {
                    notification['error']({
                      message: "發生錯誤",
                      description: "請稍後再試",
                    });
                  }}
                >
                  未開始
                </ButtonWithLoading>
                <div className={styles.modalFooterRight}>
                  {footer}
                  {renderDoneButton(elem, styles.doneButton, {
                    height: 52,
                  })}
                </div>
              </div>
            }
            style={style}
            wrapClassName={`${wrapClassName} ${styles.modalWrap}`}
          >
            {renderStartedOrderDetails(elem)}
          </Modal>
        );
      }}
    />
  );

  const renderItem = (item) => {
    const attributes = item.attributes;
    return (
      <Card
        key={item.id}
        className={styles.itemCard}
        bodyStyle={{
          fontSize: 18,
          padding: 16,
          color: "rgba(0, 0, 0, 0.85)",
        }}
        onClick={() => setSelectedItem(item.attributes_id)}
      >
        <div>
          {attributes.alternate_name
            ? attributes.alternate_name
            : attributes.name}
        </div>
        <div>
          {attributes.notes && <div>{attributes.notes}</div>}
          {attributes.modifications && (
            <div>{attributes.modifications.join(", ")}</div>
          )}
        </div>
      </Card>
    );
  };

  if (showItemSelect) {
    let filteredOrders = orders;
    let selectedItemInfo = [];
    if (selectedItem && itemMap.has(selectedItem)) {
      // itemMap should always contain this item attributes ID
      const selectedItemObj = itemMap.get(selectedItem);

      const selectedOrders = selectedItemObj.orders;
      filteredOrders = orders.filter((order) => {
        return selectedOrders.has(order.id);
      });

      const selectedItemAttributes = selectedItemObj.item.attributes;
      if (selectedItemAttributes.alternate_name) {
        selectedItemInfo.push(selectedItemAttributes.alternate_name);
      } else {
        selectedItemInfo.push(selectedItemAttributes.name);
      }
      if (selectedItemAttributes.notes) {
        selectedItemInfo.push(selectedItemAttributes.notes);
      }
      if (selectedItemAttributes.modifications) {
        selectedItemInfo.push(selectedItemAttributes.modifications.join(", "));
      }
    }

    return (
      <div className={styles.startedOrders}>
        <div className={styles.heading}>
          <span>
            {heading} {orders && <span>({orders.length})</span>}
          </span>
        </div>
        <Row>
          <Col span={8} className={styles.startedOrderSubCol}>
            {items.length > 0 ? (
              <div>
                {selectedItem && itemMap.has(selectedItem) ? (
                  <div className={styles.selectedMessage}>
                    依餐點篩選
                    <div className={styles.selectedItem}>
                      {selectedItemInfo.map((info) => (
                        <div>{info}</div>
                      ))}
                    </div>
                    <Button
                      size="large"
                      onClick={() => setSelectedItem(null)}
                      block
                    >
                      取消
                    </Button>
                  </div>
                ) : (
                  <div>{items.map(renderItem)}</div>
                )}
              </div>
            ) : (
              <Card className={styles.noItems}>
                <Empty description={"No items"} />
              </Card>
            )}
          </Col>
          <Col span={16} className={styles.startedOrderSubCol}>
            {renderOrderCol(filteredOrders, false, "0px 0px 0px 24px", "none")}
          </Col>
        </Row>
      </div>
    );
  } else {
    return renderOrderCol(orders, true, null, null);
  }
};
