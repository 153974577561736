import React from 'react'; 

import {
  Button,
  Col,
  Row,
  Space,
  Spin,
  Table,
} from "antd";

import { NotifyError } from "../NotifyError";
import { itemName, API_URL } from "../Util";
import { centsToDollar, formatAddress, getFetchT, shortDisplayTime } from "../TypedUtil";
import {
  Item,
  ItemAttributes,
  Order,
  OrderCustomer,
} from "../types/model";

type OrderDetailProps = {
  restaurant_id: string;
  order_id: string;
}

// Group items by item attribute.
type ItemGroup = {
  attributes: ItemAttributes;
  count: number;
};

export const OrderDetail = (props: OrderDetailProps) => {
  const [order, setOrder] = React.useState<Order | null>(null);

  const getOrder = () => {
    getFetchT<Order>(
      `${API_URL}/v1/r/${props.restaurant_id}/orders/${props.order_id}`,
      setOrder,
      (err) => {
        NotifyError("無法取得資料", err);
      },
    );
  };
  React.useEffect(getOrder, [props]);

  if (order === null) {
    return <Spin size="large" />
  }

  return (
    <Space direction="vertical">
      { order.customer && <CustomerInfo customer={order.customer} /> }
      <ItemTable items={order.items} />
      <div>
        { order.pretax_price && <p>Pretax Price: ${centsToDollar(order.pretax_price)}</p> }
        { order.handling_fee && <p>Handling Fee: ${centsToDollar(order.handling_fee)}</p> }
        { order.tip && <p>Tip: ${centsToDollar(order.tip)}</p> }
      </div>
    </Space>
  )
}

type CustomerInfoProps = {
  customer: OrderCustomer;
}

const CustomerInfo = (props: CustomerInfoProps) => {
  return (
    <div>
      <p>Name: {props.customer.name}</p>
      <p>Phone: {props.customer.phone}</p>
      <p>Email: {props.customer.email}</p>
      { props.customer.address && <p>Address: {formatAddress(props.customer.address)}</p> }
    </div>
  );
}

type ItemTableProps = {
  items: Item[];
}

const ItemTable = (props: ItemTableProps) => {
  const item_groups = new Map<string, ItemGroup>();
  props.items.forEach((i: Item) => {
    if (i.attributes) {
      const k = i.attributes.id;
      if (item_groups.has(k)) {
        // @ts-ignore
        item_groups.get(k).count += 1;
      } else {
        item_groups.set(k, { attributes: i.attributes, count: 1 });
      }
    }
  });

  let total_count = 0;
  const item_group_list: ItemGroup[] = [];
  for (const [k, v] of item_groups) {
    total_count += v.count;
    item_group_list.push(v);
  }

  const columns: any = [
    {
      title: 'Qty',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'Name',
      key: 'name',
      render: (text: string, record: ItemGroup) => {
        return itemName(record.attributes);
      },
    },
    {
      title: 'Notes',
      key: 'notes',
      render: (text: string, record: ItemGroup) => {
        return (
          <>
            {!!record.attributes.notes && <p>{record.attributes.notes}</p>}
            {
              record.attributes.modifications.map((mod: string, i: number) => {
                return <p>{mod}</p>;
              })
            }
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={item_group_list}
        pagination={false}
        rowKey={(r: ItemGroup) => r.attributes.id}
      />
      <div>
        <h3>Total Qty: {total_count}</h3>
      </div>
    </div>
  );
}
