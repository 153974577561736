import { getCredentials, processError } from "./Util";
import { Address } from "./types/model";

export function getFetchT<T>(url: string, handleResponse: (result: T) => void, handleError: (err: any) => void) {
  fetch(url, {
    headers: {
      Authorization: `Basic ${getCredentials()}`,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw res;
      }
      res.json().then((respJSON) => {
        handleResponse(respJSON);
      });
    })
    .catch((err) => {
      processError(err, handleError);
    });
};

export function fetchT<T> (
  method: string,
  url: string,
  requestBody: any,
  handleResponse: (result: T) => void,
  handleError: (err: any) => void,
) {
  fetch(url, {
    method: method,
    headers: {
      Authorization: `Basic ${getCredentials()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  })
    .then((res) => {
      if (!res.ok) {
        throw res;
      }
      if (handleResponse) {
        res.json().then((respJSON) => {
          handleResponse(respJSON);
        });
      }
    })
    .catch((err) => {
      processError(err, handleError);
    });
};

// Custom options to not display the year and seconds to save space.
export function shortDisplayTime(d: Date): string {
  var options: Intl.DateTimeFormatOptions = {
    month: 'short',  // Avoid MM/DD or DD/MM confusion by printing "Oct"
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,  // 24hr format FTW!
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  return d.toLocaleString("en-US", options)
};

export function formatAddress(a: Address): string {
  let parts: string[] = [];
  parts.push(a.line1);
  if (a.line2) {
    parts.push(a.line2);
  }
  parts.push(a.city);
  parts.push(a.state);
  parts.push(a.postal_code);
  return parts.join(", ");
}

export function centsToDollar(num: number): string {
  return (Math.round(num) / 100).toFixed(2);
}

export function assertNever(v: never): never {
  throw new Error(`should never get ${v}`);
}

type CsvValue = string | number | null;

export function downloadAsCsv(
  filename: string,
  rows: ReadonlyArray<Record<string, CsvValue>>,
) {
  const toString = (d: CsvValue): string => {
    if (typeof d === 'string') {
      return d;
    } else if (typeof d === 'number') {
      return d.toString();
    } else if (d === null) {
      return '';
    } else {
      assertNever(d);
    }
  };

  const header = rows.length === 0 ? '' : [...Object.keys(rows[0])].join(',');

  const rowContent = rows.map(row => (
    [...Object.values(row)].map(toString).join(',')
  ));

  const csvContent = [header, ...rowContent].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  openDownload(filename, blob);
}

function openDownload(filename: string, blob: Blob) {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    // Fallback that doesn't support filename.
    window.open(url);
  }
}

export function anonymizeEmail(email: string): string {
  if (!email) {
    return "";
  }

  const parts = email.split("@");
  if (parts.length < 2) {
    return "XXX";
  }

  return parts[0].slice(0, 4) + "...@" + parts.slice(1).join("@");
}

export function anonymizePhone(phone: string): string {
  if (!phone) {
    return "";
  }
  return `XXX-XXX-${phone.slice(-4)}`;
}
