import React from 'react'; 

import {
  Button,
  Popconfirm,
  notification,
} from "antd";

import { DeleteOutlined } from "@ant-design/icons";

import { API_URL } from "../Util";
import { DeleteAnnouncementResponse } from "../types/api";
import { Announcement } from "../types/model";
import { fetchT } from "../TypedUtil";

type AnnouncementDeleteButtonProps = {
  restaurantID: string;
  announcement: Announcement;
  onSuccess: () => void;
}

export const AnnouncementDeleteButton = (props: AnnouncementDeleteButtonProps) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);

  if (props.announcement.annoucement_type === "SHUT_DOWN") {
    return <></>
  }

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    fetchT<DeleteAnnouncementResponse>(
      "DELETE",
      `${API_URL}/v1/r/${props.restaurantID}/announcements/${props.announcement.id}`,
      {},
      (result: DeleteAnnouncementResponse) => {
        props.onSuccess();

        notification['success']({
          message: 'Success',
          description: `Deleted announcement successfully!`,
        });

        setConfirmLoading(false);
        setVisible(false);
      },
      (err: any) => {
        setConfirmLoading(false);
        setVisible(false);

        notification['error']({
          message: 'Error',
          description: '發生錯誤',
        });
      },
    );
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Popconfirm
      title={`Delete ${props.announcement.title} ?`}
      visible={visible}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button
        shape="circle"
        icon={<DeleteOutlined />}
        onClick={showPopconfirm}
        style={{ float: "right" }} />
    </Popconfirm>
  );
}
