import React from 'react'; 

import {
  Button,
  Col,
  Row,
  Space,
  Spin,
  Table as AntTable,
} from "antd";
import {
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import { NotifyError } from "../NotifyError";
import { API_URL } from "../Util";
import { getFetchT, shortDisplayTime } from "../TypedUtil";
import {
  ListShippingOrdersResponse,
} from "../types/api";
import {
  OrderBlurb,
} from "../types/model";
import { OrderDetailModal } from "./OrderDetailModal";

type OrderTabProps = {
  restaurant_id: string;

  // pending, started, done
  state: string;
}

// Display 1 order per page so it's easier to test pagination in local test
// setup.
const limitPerPage = process.env.NODE_ENV === "production" ? 50 : 1;

export const OrderTab = (props: OrderTabProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [next, setNext] = React.useState<string | null>(null);
  const [curr, setCurr] = React.useState<number>(-1);
  const [pages, setPages] = React.useState<Map<number, OrderBlurb[]>>(new Map<number, OrderBlurb[]>());

  const getNextPage = () => {
    if (pages.has(curr+1)) {
      // Already have the next page.
      setCurr((prev) => prev + 1);
      return;
    }

    const result_page = curr + 1;
    setLoading(true);

    const page_param = next ? `&page=${next}` : "";
    getFetchT<ListShippingOrdersResponse>(
      `${API_URL}/v1/r/${props.restaurant_id}/shipping_orders?limit=${limitPerPage}&state=${props.state}${page_param}`,
      (result: ListShippingOrdersResponse) => {
        if (result.next_page) {
          setNext(result.next_page);
        } else {
          setNext(null);
        }

        if (!result.next_page && !result.orders) {
          // The last page is empty. Don't proceed.
        } else {
          setPages((prev_pages) => {
            const new_pages = prev_pages;
            new_pages.set(result_page, result.orders);
            return new_pages;
          });
          setCurr((prev) => prev + 1);
        }

        setLoading(false);
      },
      (err) => {
        NotifyError("無法取得資料", err);
      },
    );
  };
  React.useEffect(getNextPage, []);

  if (loading) {
    return <Spin size="large" />
  }

  const nav_buttons = (
    <Row>
      <Col span={8}>
        <Button
          icon={<LeftOutlined />}
          disabled={loading || curr <= 0}
          onClick={() => setCurr(curr - 1)}
        />
      </Col>
      <Col span={8}>
        <div>{`Page ${curr+1}`}</div>
      </Col>
      <Col span={8}>
        <Button
          icon={<RightOutlined />}
          disabled={loading || (next === null && !pages.has(curr+1))}
          onClick={getNextPage}
        />
      </Col>
    </Row>
  );

  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'pos_order_name',
      key: 'pos_order_name',
      render: (text: string, record: OrderBlurb) => {
        return (
          <OrderDetailModal restaurant_id={props.restaurant_id} order_id={record.id} title={record.pos_order_name} />
        );
      },
    },
    {
      title: 'Create Time',
      dataIndex: 'create_time',
      key: 'create_time',
      render: (text: string) => shortDisplayTime(new Date(text)),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: OrderBlurb) => (
        <Space size="middle">
          <a>Shipping Label</a>
          <a>Packing List</a>
        </Space>
      ),
    },
  ];

  const curr_page = pages.get(curr);
  return (
    <Space size="large" direction="vertical">
      {
        curr_page && (
          <AntTable
            columns={columns}
            dataSource={curr_page}
            pagination={false}
            rowKey={(r: OrderBlurb) => r.id}
          />
        )
      }
      {nav_buttons}
    </Space>
  );
}
