import React, { useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Alert, Card, Empty, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import Page from "./Page";

import {
  capitalizeFirstLetter,
  getCredentials,
  getOrderState,
  DONE_ORDER_TYPE_COLORS,
  WS_URL,
} from "./Util";
import styles from "./css/DoneOrderView.module.css";

const maxDoneOrders = 100;

// delta is an OrderUpdate object from the backend.
// https://github.com/TicketRails/backend/blob/8dfdecdf5c272c2969384e468a0ab6ee0ae8cb3c/services/api/websocket/schema.go#L9
const updateDoneOrdersByType = (oldDoneOrdersByType, delta) => {
  var newDoneOrdersByType = { ...oldDoneOrdersByType };

  // Get all the order IDs that changed
  var changedIDs = new Set();
  if (delta.updated) {
    delta.updated.forEach((order) => {
      changedIDs.add(order.id);
    });
  }
  if (delta.deleted) {
    delta.deleted.forEach((id) => changedIDs.add(id));
  }

  // Remove all changed orders from the new state.
  Object.keys(newDoneOrdersByType).forEach((orderType) => {
    newDoneOrdersByType[orderType] = newDoneOrdersByType[orderType].filter(
      (order) => !changedIDs.has(order.id)
    );
  });

  // Add all updated done orders.
  if (delta.updated) {
    delta.updated.forEach((order) => {
      const k = getOrderState(order);
      if (k === "done") {
        const lowerOrderType = order.pos_order_type.toLowerCase();
        const orderCopy = {
          id: order.id,
          pos_order_name: order.pos_order_name,
          pos_order_id: order.pos_order_id,
          done_time: order.done_time,
        };
        if (lowerOrderType === "stripe" || lowerOrderType === "redeem") {
          newDoneOrdersByType["web"].unshift(orderCopy);
        } else if (lowerOrderType === "phone order") {
          const phoneMatch = order.notes.match(
            /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?(\d{4})/
          );
          newDoneOrdersByType[lowerOrderType].unshift({
            ...orderCopy,
            notes: phoneMatch ? `Phone number last 4: ${phoneMatch[2]}` : "",
          });
        } else if (lowerOrderType === "to go" || lowerOrderType === "togo" || lowerOrderType == "to-go") {
          newDoneOrdersByType["to go"].unshift(orderCopy);
        } else {
          newDoneOrdersByType["other"].unshift({
            ...orderCopy,
          });
        }
      }
    });
  }

  // Sort all done orders by done time in reverse
  Object.keys(newDoneOrdersByType).forEach((orderType) => {
    newDoneOrdersByType[orderType].sort((order1, order2) => {
      if (order1.done_time !== order2.done_time) {
        return (
          -1 * (Date.parse(order1.done_time) - Date.parse(order2.done_time))
        );
      }
      return order1.id.localeCompare(order2.id);
    });
  });

  // Limit each order type to maxDoneOrders
  Object.keys(newDoneOrdersByType).forEach((orderType) => {
    if (newDoneOrdersByType[orderType].length > maxDoneOrders) {
      newDoneOrdersByType[orderType] = newDoneOrdersByType[orderType].slice(0, maxDoneOrders);
    }
  });

  return newDoneOrdersByType;
};

const websocketDisconnectBanner = (
  <Alert
    message="無法連線"
    description="重新嘗試中..."
    type="error"
    showIcon
    className={styles.websocketDisconnect}
  />
);

export default () => {
  const restaurantID = sessionStorage.getItem("restaurant_id");
  if (!restaurantID) {
    const location = useLocation();
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  // Done orders organized by web, phone order, to go, other
  const [doneOrdersByType, setDoneOrdersByType] = useState({
    web: [],
    "phone order": [],
    "to go": [],
    other: [],
  });
  const [websocketDisconnect, setWebsocketDisconnect] = useState(false);
  const latestUpdateRef = useRef(null);

  useEffect(() => {
    const openWebSocket = () => {
      // Use cookie to pass auth info.
      // https://stackoverflow.com/questions/4361173/http-headers-in-websockets-client-api
      document.cookie = `x-tr-websocket-basic-auth=${getCredentials()}`;
      const url = `${WS_URL}/v1/ws/r/${restaurantID}/orders?init_state=done`;
      const ws = new W3CWebSocket(url);

      let idlePing = null;

      ws.onopen = () => {
        setWebsocketDisconnect(false);

        // Clear state since the backend only returns the latest state, and our
        // local state could be very stale if we were disconnected.
        setDoneOrdersByType({
          web: [],
          "phone order": [],
          "to go": [],
          other: [],
        });

        // Send a message every 10 seconds to keep the TCP connection busy so it
        // doesn't get killed by the load balancer.
        idlePing = setInterval(() => {
          ws.send("1");
        }, 10 * 1000);
      };

      ws.onclose = () => {
        // Websocket closed.
        // TODO: add better backoff logic to refresh after websocket disconnect
        if (idlePing) {
          clearInterval(idlePing);
        }
        setWebsocketDisconnect(true);

        // Try opening the websocket again.
        setTimeout(() => {
          openWebSocket();
        }, 3000);
      };

      ws.onmessage = (evt) => {
        const message = JSON.parse(evt.data);
        latestUpdateRef.current = Date.parse(message["ts"]);
        setDoneOrdersByType((prev) => updateDoneOrdersByType(prev, message));
      };
    };

    openWebSocket();
  }, [restaurantID]);

  const renderOrderTitle = (order) =>
    `Order ${order.pos_order_name ? order.pos_order_name : order.pos_order_id}`;

  return (
    <Page title="Done Orders 已完成訂單">
      <div className={styles.title}>Ready for Pickup 可取餐</div>
      <div className={styles.subtitle}>Sorted by done time 依完成時間排序</div>
      {websocketDisconnect && websocketDisconnectBanner}
      <div className={styles.doneOrders}>
        {Object.entries(doneOrdersByType).map(([orderType, orders]) => {
          return (
            <div
              key={orderType}
              className={styles.orderTypeCol}
              style={{ backgroundColor: DONE_ORDER_TYPE_COLORS[orderType.toLowerCase()] }}
            >
              <div className={styles.orderTypeColHeading}>
                {capitalizeFirstLetter(orderType)}
              </div>
              {orders.length > 0 ? (
                <div>
                  {orders.map((order) => (
                    <div key={order.id} className={styles.doneOrder}>
                      <span className={styles.doneOrderTitle}>
                        {order.pos_order_name
                          ? order.pos_order_name
                          : order.pos_order_id}
                      </span>
                      {order.notes && <span> - {order.notes}</span>}
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.empty}>
                  <Empty description={"No done orders"} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Page>
  );
};
