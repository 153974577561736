import { useTranslation } from 'react-i18next';

export const CLOVER_APP_ID = "15X3CSAPQXJ7J";

// Item SKU for charing pay at pickup orders on square through barcode.
// Must be a 5 digit number that exists in the square item catalog.
export const PAY_AT_PICKUP_SKU = "99999";

export const WS_URL =
  process.env.NODE_ENV === "production"
    ? "wss://app.ticketrails.com:5000"
    : "ws://localhost:5000";

export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://app.ticketrails.com:5000"
    : "http://localhost:5000";

export const STATUS = {
  UNDEFINED: undefined,
  PENDING: 0,
  STARTED: 1,
  DONE: 2,
};

export const STATUS_DISPLAY = {
  0: "未開始",
  1: "進行中",
  2: "完成",
};

export const COLORS = [
  "#004FAC", // blue
  "rgba(0, 0, 0, 0.85)", // default text color
  "#FA824C", // orange
  "#358600", // green
  "#999B08", // yellow-green
];

// Keys are lowercase
export const ORDER_TYPE_COLORS = {
  "to go": "#CC0029",
  "togo": "#CC0029",
  "here": "#b536d1",
  "phone order": "#FA824C",
  "phone": "#FA824C",
  "stripe": "#358600",
  "square": "#3c4ab5",
  "doordash": "#0A0B0B",
  "door dash": "#0A0B0B",
};

export const DONE_ORDER_TYPE_COLORS = {
  "to go": "rgba(204, 0, 41, 0.5)",
  "phone order": "rgba(250, 130, 76, 0.5)",
  web: "rgba(53, 134, 0, 0.5)",
  other: "#fafbb1",
};

export const STAR_PRIORITY = 10000;

export const getCredentials = () => {
  const username = sessionStorage.getItem("username");
  const password = sessionStorage.getItem("password");
  return btoa(`${username}:${password}`);
};

const logAndHandleError = (err, handleError) => {
  console.error(err);
  if (handleError) {
    handleError(err);
  }
};

export const processError = (err, handleError) => {
  if (err instanceof Error) {
    logAndHandleError(err, handleError);
  } else {
    console.log(
      "error response headers",
      Object.fromEntries(err.headers.entries())
    );
    err
      .text()
      .then((errorMessage) => logAndHandleError(errorMessage, handleError));
  }
};

export const getFetch = (url, handleResponse, handleError = null) => {
  fetch(url, {
    headers: {
      Authorization: `Basic ${getCredentials()}`,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw res;
      }
      res.json().then((respJSON) => {
        handleResponse(respJSON);
      });
    })
    .catch((err) => {
      processError(err, handleError);
    });
};

export const postFetch = (
  url,
  requestBody,
  handleResponse = null,
  handleError = null
) => {
  fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Basic ${getCredentials()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  })
    .then((res) => {
      if (!res.ok) {
        throw res;
      }
      if (handleResponse) {
        res.json().then((respJSON) => {
          handleResponse(respJSON);
        });
      }
    })
    .catch((err) => {
      processError(err, handleError);
    });
};

export const postFetchEmptyBody = (url, handleError) => {
  postFetch(url, {}, null, handleError);
};

// Put items with no actions at the back of the array
// Otherwise, sort by item name, then item attributes ID, then item ID.
export const sortItems = (items) => {
  if (!items) {
    return items;
  }

  const newItems = [...items];
  newItems.sort((item1, item2) => {
    if (!item1.actions && item2.actions) {
      return 1;
    }
    if (item1.actions && !item2.actions) {
      return -1;
    }

    let key1 = item1.attributes.alternate_name;
    let key2 = item2.attributes.alternate_name;
    if (key1 !== key2) {
      return key1.localeCompare(key2);
    }

    key1 = item1.attributes.name;
    key2 = item2.attributes.name;
    if (key1 !== key2) {
      return key1.localeCompare(key2);
    }

    key1 = item1.attributes_id;
    key2 = item2.attributes_id;
    if (key1 !== key2) {
      return key1.localeCompare(key2);
    }

    return item1.id.localeCompare(item2.id);
  });
  return newItems;
};

export const getOrderState = (order) => {
  if (order.done_time) {
    return "done";
  } else if (order.start_time) {
    return "started";
  } else {
    return "pending";
  }
};

export const hasNewOrders = (oldOrders, newOrders) => {
  let oldOrderSet = new Set();
  oldOrders.forEach((order) => oldOrderSet.add(order.id));
  for (const newOrder of newOrders) {
    if (!oldOrderSet.has(newOrder.id)) {
      return true;
    }
  }
  return false;
};

export const capitalizeFirstLetter = (s) =>
  `${s.charAt(0).toUpperCase()}${s.slice(1)}`;

// attrs: ItemAttributes
export const itemName = (attrs) => {
  const { _, i18n } = useTranslation();
  if (attrs.intl_name) {
    const prefix = attrs.pos_key ? "#" + attrs.pos_key : "";

    // Iterate through languages in fallback order.
    for (const lang of i18n.languages) {
      if (lang in attrs.intl_name && attrs.intl_name[lang]) {
        return prefix + " " + attrs.intl_name[lang];
      }
    }
  }

  // Fallback to old behavior.
  return attrs.alternate_name ? attrs.alternate_name : attrs.name;
};
