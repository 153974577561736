import React from "react";
import { StarTwoTone } from "@ant-design/icons";

import { COLORS, STAR_PRIORITY } from "./Util";
import styles from "./css/ItemInfo.module.css";

export default ({ item, qty, qtyColor, priority, selectedQty }) => {
  return (
    <div className={styles.item}>
      <div
        className={styles.itemQty}
        style={{ color: qtyColor ? qtyColor : COLORS[qty % COLORS.length] }}
      >
        {qty}
      </div>
      <div className={styles.itemText}>
        <span>{item.alternate_name ? item.alternate_name : item.name}</span>
        {priority === STAR_PRIORITY && (
          <StarTwoTone className={styles.star} twoToneColor="#ffc300" />
        )}
        <div className={styles.itemNotes}>
          {item.modifications && <div>{item.modifications.join(", ")}</div>}
          {item.notes && <div>{item.notes}</div>}
        </div>
        {selectedQty && (
          <div>
            已選擇:{" "}
            <span style={{ color: COLORS[selectedQty % COLORS.length] }}>
              {selectedQty}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
