import React from "react";
import { Redirect, Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import LogRocket from "logrocket";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { isLoggedIn } from "./Auth";
import Home from "./Home";
import MemberManagementPage from "./MemberManagementPage";
import AnnouncementEditorPage from "./AnnouncementEditorPage";
import CloverCallback from "./CloverCallback";
import ControlStation from "./ControlStation";
import DoneOrderView from "./DoneOrderView";
import EditorPage from "./EditorPage";
import OrderLookup from "./OrderLookup";
import StatsPage from "./StatsPage";
import ReportsPage from "./ReportsPage";
import ShippingManagementPage from "./ShippingManagementPage";
import QrCodeManagementPage from "./QrCodeManagementPage";
import RestaurantFeatureFlagPage from "./RestaurantFeatureFlagPage";
import WorkStation from "./WorkStation";
import Login from "./Login";
import { scanPrinters } from "./Print.js";

import "./css/App.less";

if (process.env.NODE_ENV === "production") {
  LogRocket.init("ljnq0y/ticketrails-prod");
}

const history = createBrowserHistory();

const PrivateRoute = ({ children, ...rest }) => {
  const loggedIn = isLoggedIn();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default () => {
  React.useEffect(() => {
    // On page load, instruct the print agent to scan for printers.
    // There's built-in throttling in the agent to handle refreshes in quick
    //succession.
    scanPrinters();
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute>
          <Route exact path="/clover/callback" component={CloverCallback} />
          <PrivateRoute exact path="/master-control-station">
            <ControlStation />
          </PrivateRoute>
          <PrivateRoute exact path="/done-order-view">
            <DoneOrderView />
          </PrivateRoute>
          <PrivateRoute exact path="/order-lookup">
            <OrderLookup />
          </PrivateRoute>
          <PrivateRoute exact path="/work-station">
            <WorkStation />
          </PrivateRoute>
          <PrivateRoute exact path="/editor">
            <EditorPage />
          </PrivateRoute>
          <PrivateRoute exact path="/announcement-editor">
            <AnnouncementEditorPage />
          </PrivateRoute>
          <PrivateRoute exact path="/shipping-management">
            <ShippingManagementPage />
          </PrivateRoute>
          <PrivateRoute exact path="/qr-management">
            <QrCodeManagementPage />
          </PrivateRoute>
          <PrivateRoute exact path="/flags">
            <RestaurantFeatureFlagPage />
          </PrivateRoute>
          <PrivateRoute exact path="/stats">
            <StatsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/reports">
            <ReportsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/members">
            <MemberManagementPage />
          </PrivateRoute>
          <Route exact path="/login" component={Login} />
          <Route exact path="/healthz" render={() => <div>OK</div>} />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  );
};
