import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { ClearOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useTranslation } from 'react-i18next';

import { API_URL } from "./Util";
import { fetchT } from "./TypedUtil";
import styles from "./css/ClearAllButton.module.css";

export type ItemAttributeGroup = {
  count: number;
  item_attributes: { id: string };
  oldest_pending_time: string;
  newest_pending_time: string;
};

export type ItemsByPriority = [number, Array<ItemAttributeGroup>];

export default ({ itemsByPriorityArr, restaurantID, workstationID }: {
  itemsByPriorityArr: Array<ItemsByPriority>;
  restaurantID: string;
  workstationID: string;
}) => {
  const { t, i18n } = useTranslation();

  // Cutoff time - all tasks that are pending before this are cleared.
  const [endPendingTime, setEndPendingTime] = useState<string | null>(null);
  const [clearingInProgress, setClearingInProgress] = useState(false);

  const onCancel = () => setEndPendingTime(null);

  return (
    <>
      <Button
        type="primary"
        shape="circle"
        size="large"
        icon={<ClearOutlined style={{ fontSize: "25px" }} />}
        style={{ width: "70px", height: "70px" }}
        onClick={() => {
          const pendingTimes = itemsByPriorityArr.flatMap(([priority, itemGroups]) => itemGroups.map(ig => ig.newest_pending_time));
          pendingTimes.sort().reverse();
          if (pendingTimes.length === 0) {
            return;
          }

          // Set the state so new tasks that arrive at the workstation after we
          // click the button are not automatically included in this clear
          // attempt.
          setEndPendingTime(pendingTimes[0]);
        }}
      />
      <Modal
        visible={endPendingTime != null}
        onCancel={onCancel}
        footer={[
          <Button key="cancel" onClick={onCancel} size="large">
            {t("Cancel")}
          </Button>,
          <Button
            key="clear"
            type="primary"
            size="large"
            disabled={endPendingTime == null}
            loading={clearingInProgress}
            onClick={() => {
              setClearingInProgress(true);

              fetchT<{}>(
                "POST",
                `${API_URL}/v1/r/${restaurantID}/workstations/${workstationID}/clear`,
                {
                  end_pending_time: endPendingTime,
                },
                () => {
                  setClearingInProgress(false);
                  setEndPendingTime(null);
                },
                (err) => {
                  // TODO(kku): using message instead of notification for now
                  // since we make a separate call for each item, which
                  // results in a lot of notifications.
                  message.error("發生錯誤，請稍後再試");
                  setClearingInProgress(false);
                  setEndPendingTime(null);
                }
              );
            }}
          >
            {t("Clear")}
          </Button>,
        ]}
      >
        <div className={styles.confirm}>
          <ExclamationCircleOutlined className={styles.icon} />
          {t("ws_ClearAllItems")}
        </div>
      </Modal>
    </>
  );
};
