import {
  notification,
} from 'antd';

export function NotifyError(title: string, err: any) {
  notification['error']({
    message: title,
    description: ExtractErrorMessage(err),
  });
};

export function ExtractErrorMessage(err: any): string {
  let description: string = "";
  try {
    description = JSON.parse(err)["message"];
  } catch(e) {
    description = "發生錯誤";
  }
  return description;
};
