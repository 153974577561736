import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import Page from "./Page";
import { QrCodeEditor } from "./QrCodeManagement/Editor";

export default () => {
  const restaurantID = sessionStorage.getItem("restaurant_id");
  if (!restaurantID) {
    const location = useLocation();
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Page title={`店內 QR Code`}>
      <QrCodeEditor restaurant_id={restaurantID} />
    </Page>
  );
};
