import React from 'react'; 

import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
} from "antd";

import moment from 'moment';

import { API_URL } from "../Util";
import { fetchT } from "../TypedUtil";
import {
  AdjustCustomerPointsRequest,
  AdjustCustomerPointsResponse,
} from "../types/api";

type AdjustCustomerPointsProp = {
  restaurant_id: string;

  // If supplied, only allow adjusting points for this customer.
  email_or_phone?: string;

  onSuccess?: () => void;
}

export const AdjustCustomerPointsForm = (props: AdjustCustomerPointsProp) => {
  const [saveLoading, setSaveLoading] = React.useState(false);

  const [form] = Form.useForm();

  const handleSave = (req: AdjustCustomerPointsRequest) => {
    setSaveLoading(true);

    fetchT<AdjustCustomerPointsResponse>(
      "POST",
      `${API_URL}/v1/r/${props.restaurant_id}/customer_points`,
      req,
      (result: AdjustCustomerPointsResponse) => {
        notification['success']({
          message: 'Success',
          description: `Added ${req.delta} points successfully!`,
        });
        form.resetFields();
        setSaveLoading(false);
        if (props.onSuccess) {
          props.onSuccess();
        }
      },
      (err: any) => {
        setSaveLoading(false);

        let description: string = "";
        try {
          description = JSON.parse(err)["message"];
        } catch(e) {
          description = "發生錯誤";
        }

        notification['error']({
          message: 'Error',
          description: description,
        });
      },
    );
  };

  const initialValues: AdjustCustomerPointsRequest = {
    email_or_phone: props.email_or_phone ? props.email_or_phone : "",
    delta: 0,
    description: "Manually added by restaurant",
    expire_time: moment().startOf('day').add(6, 'month'),
  };

  React.useEffect(() => {
    form.setFieldsValue({
      "email_or_phone": props.email_or_phone ? props.email_or_phone : "",
    });
  }, [props.email_or_phone]);

  const formElem = (
    <Form
      form={form}
      labelCol={{ span: 10 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      colon={false}
      initialValues={initialValues}
      onFinish={handleSave}
    >
      <Form.Item
        label={"Email or Phone"}
        name="email_or_phone"
        rules={[
          {
            required: true,
            message: "Email or phone is required.",
          },
        ]}
      >
        <Input disabled={!!props.email_or_phone} />
      </Form.Item>
      <Form.Item
        label={"Points"}
        name="delta"
        rules={[
          {
            required: true,
            message: "Points is required.",
          },
        ]}
      >
        <InputNumber
          max={1000}
          step={1}
          precision={0}
        />
      </Form.Item>
      <Form.Item
        label={"Description"}
        name="description"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Expiration"}
        name="expire_time"
        rules={[
            {
            required: true,
            message: "Expiration date is required for adding credits.",
            },
        ]}
        >
        <DatePicker />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          block
          htmlType="submit"
          loading={saveLoading}
          disabled={saveLoading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );

  return formElem;
}
