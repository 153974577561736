import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Divider, Row } from "antd";

import Page from "./Page";
import { getFetch, API_URL } from "./Util";
import { isAdmin } from "./Auth";

export default () => {
  const history = useHistory();

  const [controlStations, setControlStations] = useState([]);

  useEffect(() => {
    const restaurantID = sessionStorage.getItem("restaurant_id");
    if (!restaurantID) {
      return;
    }
    getFetch(`${API_URL}/v1/r/${restaurantID}/control_stations`, (res) => {
      if (res && res.control_stations && res.control_stations.length > 0) {
        setControlStations(res.control_stations);
      }
    });
  }, []);

  return (
    <Page noBack>
      <div style={{ fontSize: "30px" }}>
        {
          controlStations.map((cs) => {
            return controlStationLinks(history, cs);
          })
        }
        <Divider />

        <Row>
          <a onClick={() => history.push("/master-control-station")}>外場訂單控制台</a>
        </Row>
        <Row>
          <a onClick={() => history.push("/done-order-view")}>顯示完成訂單</a>
        </Row>
        <Row>
          <a onClick={() => history.push("/order-lookup")}>訂單查尋</a>
        </Row>

        <Divider />
        <h4>管理功能</h4>
        {
          isAdmin() ? (
            <div>
              <Row>
                <a onClick={() => history.push("/editor")}>編輯菜單</a>
              </Row>
              <Row>
                <a onClick={() => history.push("/announcement-editor")}>公告設定</a>
              </Row>
              <Row>
                <a onClick={() => history.push("/members")}>管理會員</a>
              </Row>
              <Row>
                <a onClick={() => history.push("/stats")}>統計</a>
              </Row>
              <Row>
                <a onClick={() => history.push("/reports")}>報表</a>
              </Row>
              <Row>
                <a onClick={() => history.push("/qr-management")}>店內 QR Code</a>
              </Row>
            </div>
          ) : <p style={{ fontSize: "18px" }}>必須使用管理員帳號登入才能使用</p>
        }
      </div>
    </Page>
  );
};

const controlStationLinks = (history, control_station) => {
  const selectWorkstation = (ws) => {
    // Store workstation ID name in state for redirect to workstation page.
    history.push("/work-station", {
      workStationID: ws.id,
      workStationName: ws.name,
    });
  };

  return (
    <Row key={control_station.name}>
      <div style={{ width: "100%" }}>{control_station.name}</div>
      {
        control_station.workstation_schemas.map((ws) => {
          return (
            <div style={{ width: "100%" }} key={ws.id}>
              &nbsp;&nbsp;&nbsp;&nbsp;<a onClick={() => selectWorkstation(ws)}>{ws.name}</a>
            </div>
          );
        })
      }
    </Row>
  );
};
