import React from 'react'; 

import { Button, Spin, Table, Typography, notification } from "antd";
import { CloudDownloadOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';

import { API_URL } from "../Util";
import { getFetchT, shortDisplayTime, centsToDollar, downloadAsCsv } from '../TypedUtil';
import { GetAbbreviatedOrdersResponse, AbbreviatedOrder } from '../types/api';

const { Title } = Typography;

export type OrderTableProps = {
  restaurant_id: string;
  start_time: Moment;
  end_time: Moment;
};

export function OrderTable({
  restaurant_id,
  start_time,
  end_time,
}: OrderTableProps) {
  const [orders, setOrders] = React.useState<ReadonlyArray<AbbreviatedOrder> | null>(null);

  React.useEffect(() => {
    // Go into loading state first.
    setOrders(null);

    getFetchT<GetAbbreviatedOrdersResponse>(
      `${API_URL}/v1/r/${restaurant_id}/stats/${start_time.toISOString()}/${end_time.toISOString()}/orders`,
      (resp: GetAbbreviatedOrdersResponse) => setOrders(resp.abbreviated_orders),
      (err: any) => {
        console.log("failed to get abbrebiated orders for stats", err);
        notification['error']({
          message: "發生錯誤",
          description: "請稍後再試",
        });
      }
    )
  }, [restaurant_id, start_time, end_time]);

  const columns = [
    {
      title: 'Payment Processor',
      dataIndex: 'payment_processor',
      key: 'payment_processor',
    },
    {
      title: 'Download',
      key: 'download',
      render: (_: unknown, record: { payment_processor: string }) => {
        const pp = record.payment_processor;

        const rowsToExport = ([...(orders ?? [])]).
          sort((o1, o2) => o1.create_time > o2.create_time ? 1 : -1).
          filter(o => o.payment_processor === pp).
          map(o => ({
            'Order Type': o.pos_order_type,
            'Order Name': o.pos_order_name,
            'Create Time': moment(o.create_time).format("YYYY/MM/DD HH:mm"),
            'Pretax Price': o.pretax_price == null ? null : centsToDollar(o.pretax_price),
            'Tip': o.tip == null ? null : centsToDollar(o.tip),
            'Handling Fee': o.handling_fee == null ? null : centsToDollar(o.handling_fee),
            'Payment Processor': o.payment_processor,
          }));

        const makeCsv = () => {
          downloadAsCsv(
            `${pp}_${start_time.format("YYYYMMDD")}_${end_time.format("YYYYMMDD")}.csv`,
            rowsToExport,
          );
        };

        return <Button
          icon={<CloudDownloadOutlined />}
          size='large'
          type='primary'
          shape='round'
          onClick={makeCsv}
        />
      },
    },
  ];

  return (
    <>
      <Title level={4}>Payments</Title>
      {
        orders == null
        ? <Spin size="large"/>
        : (
          <Table columns={columns} dataSource={[{ 'payment_processor': 'STRIPE' }]}/>
        )
      }
    </>
  );
}
