// TODO: fetch slug during login and save in react context.
export const restaurantIdToSlug: Record<string, string> =
  process.env.NODE_ENV === "development"
    ? {
        // Dev env restaurant ids
        // O3 Canyon
        'rst_CWNf_VBxQL6YEWSFe4C7RQ': 'cupertino',
        // O4 Gorge
        'rst_vQ9-gZU6SZeS0nmkYHH30w': 'palo-alto',
      }
    : {
        // Prod env restaurant ids
        'rst_ogYUfU3LS-yz4Pgy_oBTYA': 'cupertino',
        'rst_2nWzTgaAT8K-eS7jfprmkA': 'palo-alto',
      };
