import React from 'react'; 

import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  notification,
} from "antd";

import { API_URL } from "../Util";
import { fetchT } from "../TypedUtil";
import { NotifyError } from "../NotifyError";
import {
  CreateTableRequest,
  CreateTableResponse,
} from "../types/api";

type NewTableFormProps = {
  restaurant_id: string;
  onSuccess: () => void;
}

export const NewTableForm = (props: NewTableFormProps) => {
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [form] = Form.useForm();

  const handleSave = (req: CreateTableRequest) => {
    setSaveLoading(true);

    fetchT<CreateTableResponse>(
      "POST",
      `${API_URL}/v1/r/${props.restaurant_id}/tables`,
      req,
      (result: CreateTableResponse) => {
        notification['success']({
          message: 'Success',
          description: `Added new table successfully!`,
        });
        form.resetFields();
        setSaveLoading(false);
        props.onSuccess();
      },
      (err: any) => {
        setSaveLoading(false);
        NotifyError("無法新增座位", err);
      },
    );
  };

  return (
    <Form
      form={form}
      initialValues={{
        name: '',
        pos_order_type: 'Here',
      }}
      labelCol={{ span: 10 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      colon={false}
      onFinish={handleSave}
    >
      <Form.Item
        label={"Name"}
        name="name"
        rules={[
          {
            required: true,
            message: "Name is required.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Order Type"}
        name="pos_order_type"
        rules={[
          {
            required: true,
            message: "Order type is required.",
          },
        ]}
      >
        <Select>
          <Select.Option value="Here">Here</Select.Option>
          <Select.Option value="ToGo">ToGo</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          block
          htmlType="submit"
          loading={saveLoading}
          disabled={saveLoading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
