import React from 'react'; 

import {
  Col,
  Collapse,
  Divider,
  Input,
  Row,
  Result,
  Spin,
  notification,
} from "antd";

import { API_URL } from "../Util";
import { getFetchT } from "../TypedUtil";
import { NotifyError } from "../NotifyError";
import {
  Customer,
} from "../types/model";

import { BasicInfo } from "./BasicInfo";
import { OrderHistory } from "./OrderHistory";
import { PointHistoryTable } from "./PointHistory";
import { AdjustCustomerPointsForm } from "../AdjustCustomerPoints/Form";

const { Panel } = Collapse;
const { Search } = Input;

type MemberManagementProp = {
  restaurant_id: string;
}

export const MemberManagement = (props: MemberManagementProp) => {
  const [customer, setCustomer] = React.useState<Customer | null>(null);
  const [pointsVersion, setPointsVersion] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<boolean>(false);

  const getCustomer = (emailOrPhone: string) => {
    setLoading(true);
    setCustomer(null);

    const url = `${API_URL}/v1/r/${props.restaurant_id}/customers?eop=` + encodeURIComponent(emailOrPhone);
    getFetchT<Customer[]>(
      url,
      (customers: Customer[]) => {
        setLoading(false);

        if (customers.length === 0) {
          NotifyError("會員不存在", "{'message': '會員不存在'}");
        } else {
          // Special logic to hide backend bug that treat 163.com emails as "+1163266"
          // We can't remove the record from the db yet in case we need to rollback
          // backend.
          // TODO(kku): remove this hack once we know we won't rollback backend beyond
          // ea6ccde5b586303d860e44580a7007a52c419606
          if (customers[0].phone === "+1163266" || customers[0].phone === "+1126266") {
            customers[0].phone = "";
          }

          // TODO(kku): Support selecting from customer list.
          setCustomer(customers[0]);
        }
      },
      (err: any) => {
        setLoading(false);
        NotifyError("無法搜尋會員", err);
      }
    );
  };

  const onPointsUpdate = () => {
    setPointsVersion((prev) => prev + 1);
  };

  return (
    <div>
      <Search placeholder="Email or Phone" onSearch={getCustomer} enterButton />
      <Divider/>
      {
        customer === null
        ? ( loading ? <Spin size="large" /> : <Result status="warning" title="No Result" subTitle="Try searching again" />)
        : (
          <div>
            <BasicInfo customer={customer} />
            <Divider/>
            <Row gutter={[8,8]}>
              <Col sm={24} md={12}>
                <Collapse accordion defaultActiveKey="point_history">
                  <Panel header="訂單紀錄" key="order_history">
                    <OrderHistory customer={customer} restaurant_id={props.restaurant_id}/>
                  </Panel>
                  <Panel header="點數紀錄" key="point_history">
                    <PointHistoryTable customer={customer} restaurant_id={props.restaurant_id} version={pointsVersion} />
                  </Panel>
                </Collapse>
              </Col>
              <Col sm={24} md={12}>
                <h2>調整點數</h2>
                <AdjustCustomerPointsForm
                  restaurant_id={props.restaurant_id}
                  email_or_phone={customer.email ? customer.email : customer.phone}
                  onSuccess={onPointsUpdate} />
              </Col>
            </Row>
          </div>
        )
      }
    </div>
  );
}
