import React from 'react'; 

import {
  Table,
} from "antd";

import moment from "moment";

import { RankStats, TimedRankStats } from "../types/api";

export type TimedRankStatsTableProps = {
  data: TimedRankStats;
};

type FlatTimedRankStats = RankStats & { timestamp: string };

export const TimedRankStatsTable = (props: TimedRankStatsTableProps) => {
  // Flatten into list of rows.
  let rows: Array<FlatTimedRankStats> = [];
  Object.entries(props.data).forEach(([ts, stats]) => {
    let row : FlatTimedRankStats = { ... stats, timestamp: ts };
    rows.push(row);
  });

  const columns: any = [
    {
      title: "Time",
      dataIndex: "timestamp",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      render: (text: string, record: FlatTimedRankStats, index: string) => {
        return moment(text).format("MM/DD")
      },
      sorter: (a: FlatTimedRankStats, b: FlatTimedRankStats) => {
        const at = moment(a.timestamp);
        const bt = moment(b.timestamp);
        if (at.isBefore(bt)) {
          return -1;
        } else if (at.isSame(bt)) {
          return 0;
        }
        return 1;
      },
    },
    {
      title: "Average",
      dataIndex: "avg",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      render: (text: string, record: FlatTimedRankStats, index: string) => {
        // Round to 2 decimal places
        return parseFloat(text).toFixed(2);
      },
      sorter: (a: FlatTimedRankStats, b: FlatTimedRankStats) => {
        return a.avg - b.avg;
      },
    },
    {
      title: "p50",
      dataIndex: "p50",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      render: (text: string, record: FlatTimedRankStats, index: string) => {
        // Round to 2 decimal places
        return parseFloat(text).toFixed(2);
      },
      sorter: (a: FlatTimedRankStats, b: FlatTimedRankStats) => {
        return a.p50 - b.p50;
      },
    },
    {
      title: "p95",
      dataIndex: "p95",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      render: (text: string, record: FlatTimedRankStats, index: string) => {
        // Round to 2 decimal places
        return parseFloat(text).toFixed(2);
      },
      sorter: (a: FlatTimedRankStats, b: FlatTimedRankStats) => {
        return a.p95 - b.p95;
      },
    },
    {
      title: "p99",
      dataIndex: "p99",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      render: (text: string, record: FlatTimedRankStats, index: string) => {
        // Round to 2 decimal places
        return parseFloat(text).toFixed(2);
      },
      sorter: (a: FlatTimedRankStats, b: FlatTimedRankStats) => {
        return a.p99 - b.p99;
      },
    },
    {
      title: "Min",
      dataIndex: "min",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      render: (text: string, record: FlatTimedRankStats, index: string) => {
        // Round to 2 decimal places
        return parseFloat(text).toFixed(2);
      },
      sorter: (a: FlatTimedRankStats, b: FlatTimedRankStats) => {
        return a.min - b.min;
      },
    },
    {
      title: "Max",
      dataIndex: "max",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      render: (text: string, record: FlatTimedRankStats, index: string) => {
        // Round to 2 decimal places
        return parseFloat(text).toFixed(2);
      },
      sorter: (a: FlatTimedRankStats, b: FlatTimedRankStats) => {
        return a.max - b.max;
      },
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={rows} />
    </div>
  );
};
