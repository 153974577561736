import React from 'react'; 
import moment from "moment";

import {
  Spin,
  Table,
} from "antd";

import { API_URL } from "../Util";
import { getFetchT } from "../TypedUtil";
import { NotifyError } from "../NotifyError";
import {
  Customer,
  CustomerOrder,
} from "../types/model";

type OrderHistoryProp = {
  customer: Customer;
  restaurant_id: string;
}

export const OrderHistory = (props: OrderHistoryProp) => {
  const [orders, setOrders] = React.useState<CustomerOrder[] | undefined>(undefined);

  React.useEffect(() => {
    const url = `${API_URL}/v1/r/${props.restaurant_id}/customers/${props.customer.id}/orders`;
    getFetchT<CustomerOrder[]>(
      url,
      setOrders,
      (err: any) => {
        NotifyError("無法取得訂單紀錄", err);
      }
    );
  }, [props.customer]);

  if (orders === undefined) {
    return <Spin size="large" />;
  }

  const columns: any = [
    {
      title: "Date",
      dataIndex: "create_time",
      key: "create_time",
      defaultSortOrder: "descend",
      sortDirections: ["ascend", "descend"],
      sorter: (a: CustomerOrder, b: CustomerOrder) => {
        return a["create_time"].localeCompare(b["create_time"]);
      },
      render: (t: string, a: CustomerOrder, index: number) => {
        return moment(t).format("YYYY/MM/DD");
      },
    },
    {
      title: "Order Number",
      dataIndex: "pos_order_name",
      key: "pos_order_name",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      sorter: (a: CustomerOrder, b: CustomerOrder) => {
        return a["pos_order_name"].localeCompare(b["pos_order_name"]);
      },
    },
    {
      title: "Pretax Price",
      dataIndex: "pretax_price",
      key: "pretax_price",
      render: (t: string, a: CustomerOrder, index: number) => {
        if (a.pretax_price) {
          return a.pretax_price / 100;
        }
        return "N/A";
      },
    },
    {
      title: "Tip",
      dataIndex: "tip",
      key: "tip",
      render: (t: string, a: CustomerOrder, index: number) => {
        if (a.tip) {
          return a.tip / 100;
        }
        return "N/A";
      },
    },
  ];

  return <Table dataSource={orders} columns={columns} />;
};
