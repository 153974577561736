import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import Page from "./Page";
import { AnnouncementEditor } from "./AnnouncementEditor/Editor";

export default () => {
  const restaurantID = sessionStorage.getItem("restaurant_id");
  if (!restaurantID) {
    const location = useLocation();
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Page title={`公告設定`}>
      <AnnouncementEditor restaurantID={restaurantID} />
    </Page>
  );
};
