import React from 'react'; 

import {
  Collapse,
  Spin,
  Table as AntTable,
} from "antd";
import {
  EditOutlined,
} from "@ant-design/icons";

import { NotifyError } from "../NotifyError";
import { API_URL } from "../Util";
import { getFetchT } from "../TypedUtil";
import {
  ListTablesResponse,
} from "../types/api";
import {
  Table,
} from "../types/model";

import { NewTableForm } from "./Form";
import { TableDeleteButton } from "./DeleteButton";
import { TableQRCodeButton } from "./QRCodeButton";

const { Panel } = Collapse;

type QrCodeManagementProps = {
  restaurant_id: string;
}

export const QrCodeEditor = (props: QrCodeManagementProps) => {
  const [tables, setTables] = React.useState<Table[] | undefined>(undefined);

  const refreshTables = () => {
    getFetchT<ListTablesResponse>(
      `${API_URL}/v1/r/${props.restaurant_id}/tables`,
      (result: ListTablesResponse) => {
        setTables(result.tables);
      },
      (err) => {
        NotifyError("無法取得座位", err);
      },
    );
  };

  // Load current set of announcemets.
  React.useEffect(refreshTables, [props.restaurant_id]);

  if (tables === undefined) {
    // Still loading
    return (
      <Spin size="large"/>
    );
  }

  const onChange = () => {
    // Set tables to undefined to show loading icon again.
    setTables(undefined);
    refreshTables();
  };

  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      render: (text: string, record: Table) => (
        <TableQRCodeButton
          restaurant_id={props.restaurant_id}
          table_id={record.id}
          table_name={record.name}
        />
      ),
    },
    {
      render: (text: string, record: Table) => (
        <TableDeleteButton
          restaurant_id={props.restaurant_id}
          table={record}
          onSuccess={onChange}
        />
      ),
    },
  ];

  return (
    <div>
      <Collapse defaultActiveKey={["new", "current"]}>
        <Panel header="Create New QR Code" key="new">
          <NewTableForm
            restaurant_id={props.restaurant_id}
            onSuccess={onChange}
          />
        </Panel>
        <Panel header="Current QR Codes" key="current">
          <AntTable
            columns={columns}
            dataSource={tables}
          />
        </Panel>
      </Collapse>
    </div>
  );
}
