import { TimedCounts, TimedRankStats } from "../types/api";

type StatsDownloadProps = {
    filename: string;
    data?: TimedCounts;
}

const getRow = (data: TimedCounts) => {
  let timestamps: string[] = [];
  let rowMap: Map<string, any> = new Map<string, any>();;
  Object.entries(data).forEach(([ts, counts]) => {
    timestamps.push(ts);
    Object.entries(counts).forEach(([key, v]) => {
      let row = rowMap.get(key);
      if (row === undefined) {
        row = {};
        rowMap.set(key, row);
      }
      row[ts] = v;
    });
  });

  let rows: any = [];
  for (let [key, m] of rowMap) {
    const row = { ... m };
    row["xxx_tr_row_key"] = key;
    rows.push(row);
  }

  return {timestamps, rows};
}

export const exportToCsv = (props: StatsDownloadProps): void => {
    const {filename, data} = props;
    console.log(data);
    if (!data) {
        return;
    }
    const separator: string = ",";
    const {timestamps, rows} = getRow(data);

    const csvContent = 
        "Name" + "," + 
        timestamps.join(separator) + '\n' + 
        rows.map((row: { [x: string]: any; }) => {
            return row["xxx_tr_row_key"] + "," + timestamps.map(ts => {
                return row[ts];
            }).join(separator);
        }).join('\n');

    console.log(csvContent);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}