import React from 'react'; 
import moment from "moment";

import {
  Col,
  Divider,
  Input,
  Row,
  Statistic,
  notification,
} from "antd";

import {
  Customer,
} from "../types/model";

type BasicInfoProp = {
  customer: Customer;
}

export const BasicInfo = (props: BasicInfoProp) => {
  return (
    <Row gutter={[1,8]}>
      <Col span={12}>
        <Statistic title="Name" value={props.customer.name} />
      </Col>
      <Col span={12}>
        <Statistic title="Member Since" value={moment(props.customer.create_time).format("YYYY/MM/DD")} />
      </Col>
      <Col span={12}>
        <Statistic title="Email" value={props.customer.email ? props.customer.email : "N/A"} />
      </Col>
      <Col span={12}>
        <Statistic title="Phone" value={props.customer.phone ? props.customer.phone : "N/A"} />
      </Col>
    </Row>
  );
};
