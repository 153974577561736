import React, { useState } from "react";
import { Card, Modal, Radio, notification } from "antd";

import { useTranslation } from 'react-i18next';

import ButtonWithLoading from "./ButtonWithLoading";

import { API_URL, itemName } from "./Util";
import styles from "./css/ItemCard.module.css";

export default ({ item, priority, showBigFont, restaurantID, workstationID }) => {
  const [showModal, setShowModal] = useState(false);
  const [doneCount, setDoneCount] = useState(0);

  const { t, i18n } = useTranslation();

  const attributes = item.item_attributes;
  const doneDisabled = doneCount <= 0;
  const countButtons = Array.from({ length: item.count }, (_, i) => i + 1);

  const itemDetails = (style) => (
    <div className={styles.itemInfo} style={style}>
      <div>
        {itemName(attributes)}
      </div>
      <div>
        {attributes.modifications && (
          <div>{attributes.modifications.join(", ")}</div>
        )}
        {attributes.notes && <div>{attributes.notes}</div>}
      </div>
    </div>
  );

  let cardStyle = styles.itemCard;
  if (priority === "10000" || priority === 10000) {
    cardStyle = styles.itemCardStarred;
  } else if (priority === "3000" || priority === 3000) {
    cardStyle = styles.itemCardTogo;
  } else if (priority === "5000" || priority === 5000) {
    cardStyle = styles.itemCardTogo;
  }

  return (
    <Card
      className={cardStyle}
      bodyStyle={{
        padding: 0,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <div className={styles.itemCardBody} onClick={() => setShowModal(true)}>
        {itemDetails({ fontSize: showBigFont ? 32 : 18 })}
        <div className={styles.itemCount}>{item.count}</div>
      </div>
      <Modal
        visible={showModal}
        onCancel={() => {
          setDoneCount(0);
          setShowModal(false);
        }}
        footer={null}
        style={{ maxWidth: "90%", userSelect: "none", fontSize: 18 }}
      >
        <div className={styles.itemDetails}>
          {itemDetails({ fontSize: 18 })}
          <Radio.Group
            size="large"
            value={doneCount}
            onChange={(e) => {
              setDoneCount(e.target.value);
            }}
            className={styles.countButtons}
          >
            {countButtons.map((count) => (
              <Radio.Button
                key={count}
                value={count}
                className={styles.countButton}
              >
                {count}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        <ButtonWithLoading
          disabled={doneDisabled}
          className={
            doneDisabled
              ? styles.doneButton
              : `${styles.doneButton} ${styles.doneButtonColor}`
          }
          postURL={`${API_URL}/v1/r/${restaurantID}/workstations/${workstationID}/complete_tasks`}
          postBody={{
            item_attributes_id: attributes.id,
            count: doneCount,
          }}
          postHandleResponse={() => {
            setDoneCount(0);
            setShowModal(false);
          }}
          postHandleError={() => {
            notification['error']({
              message: "發生錯誤",
              description: "請稍後再試",
            });
          }}
        >
          {t("Done")}
        </ButtonWithLoading>
      </Modal>
    </Card>
  );
};
