import React from 'react'; 

import {
  Tabs,
} from "antd";

import { OrderTab } from './OrderTab';

const { TabPane } = Tabs;


type ShippingManagerProps = {
  restaurant_id: string;
}

export const ShippingManager = (props: ShippingManagerProps) => {
  const states = [
    { title: "Pending 未開始", key: "pending" },
    { title: "Pending 進行中", key: "started" },
    { title: "Done 完成", key: "done" },
  ];

  return (
    <Tabs defaultActiveKey="pending">
      {
        states.map(s => {
          return (
            <TabPane tab={s.title} key={s.key}>
              <OrderTab state={s.key} restaurant_id={props.restaurant_id} />
            </TabPane>
          );
        })
      }
    </Tabs>
  );
}
