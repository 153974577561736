import React from 'react'; 

import {
  Button,
  Col,
  Row,
  Spin,
  notification,
} from "antd";
import {
  ArrowRightOutlined,
  LineChartOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import { API_URL } from "../Util";
import { getFetchT } from "../TypedUtil";
import { Counts, RankStats, TimedCounts, TimedRankStats, TimedStats } from "../types/api";
import { TimedCountsPlot } from "./TimedCountsPlot";
import { TimedCountsTable } from "./TimedCountsTable";
import { TimedRankStatsTable } from "./TimedRankStatsTable";
import { exportToCsv } from './StatsUtil';

type StatsViewProps = {
  restaurant_id: string;
}

// Divide all entries in TimedCounts by a number.
function divideTimedCounts(tc: TimedCounts, n: number): TimedCounts {
  let result: TimedCounts = {};
  Object.entries(tc).forEach(([ts, counts]) => {
    result[ts] = {};
    Object.entries(counts).forEach(([key, v]) => {
      result[ts][key] = v/n;
    });
  });
  return result;
}

// Divide all entries in TimedRankStats (except count) by a number.
function divideTimedRankStats(tr: TimedRankStats, n: number): TimedRankStats {
  let result: TimedRankStats = {};
  Object.entries(tr).forEach(([ts, stats]: [string, RankStats]) => {
    result[ts] = {... stats };
    for (let key of Object.keys(stats)) {
      if (key !== "count") {
        // TypeScript does not allow generic string to be used as field accessor.
        // @ts-ignore
        result[ts][key] = result[ts][key]/n;
      }
    }
  });
  return result;
}

export const StatsView = (props: StatsViewProps) => {
  const week_ago = moment().startOf("day").subtract(7, "days");
  const tomorrow = moment().startOf("day").add(1, "days");

  const [stats, setStats] = React.useState<TimedStats | null >(null);
  const [start_time, setStartTime] = React.useState<moment.Moment>(week_ago);
  const [end_time, setEndTime] = React.useState<moment.Moment>(tomorrow);
  const [display_plot, setDisplayPlot] = React.useState<boolean>(true);

  // Limit end time to 2 weeks from start to avoid overloading backend.
  const two_weeks_from_start = start_time.clone().add(14, "days");
  const max_end_time = two_weeks_from_start < tomorrow ? two_weeks_from_start : tomorrow;

  const getStats = () => {
    getFetchT<TimedStats>(
      `${API_URL}/v1/r/${props.restaurant_id}/stats/${start_time.toISOString(true)}/${end_time.toISOString(true)}/daily`,
      (result: TimedStats) => {
        setStats(result);
      },
      (err) => {
        notification['error']({
          message: 'Error',
          description: '發生錯誤',
        });
      },
    );
  };
  React.useEffect(getStats, [props.restaurant_id]);

  if (stats === null) {
    // Still loading
    return (
      <Spin size="large"/>
    );
  }

  return (
    <div>
      <Row>
        <Col span={2}>
          <DatePicker
            label="Start Date"
            value={start_time}
            onChange={(date) => {
              if (date) {
                setStartTime(date);
              }
            }}
            format="yyyy/MM/DD"
          />
        </Col>
        <Col span={2} style={{ fontSize: "30px", textAlign: "center" }}>
          <ArrowRightOutlined />
        </Col>
        <Col span={2}>
          <DatePicker
            label="End Date"
            value={end_time}
            minDate={start_time}
            minDateMessage="End date cannot be before start date."
            maxDate={max_end_time}
            maxDateMessage="End date cannot be more than 14 days from start date."
            onChange={(date) => {
              if (date) {
                setEndTime(date);
              }
            }}
            format="yyyy/MM/DD"
          />
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={() => { setStats(null); getStats(); }} >
            查尋
          </Button>
        </Col>
        <Col span={16}>
          <div style={{ float: "right", fontSize: "30px" }}>
          {
            display_plot
            ? (
              <Button icon={<TableOutlined />} onClick={() => setDisplayPlot(false)} >
                Show Table
              </Button>
            )
            : (
              <Button icon={<LineChartOutlined />} onClick={() => setDisplayPlot(true)}>
                Show Plot
              </Button>
            )
          }
          </div>
        </Col>
      </Row>
      <Row><h1/></Row>
      <Row>
        <Col span={2}>
          <h1>Orders</h1>
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={() => exportToCsv({filename: "order_stats.csv", data: stats.order_stats})} >
            Download table
          </Button>
        </Col>
      </Row>
      <Row>
        {
          display_plot
          ? <TimedCountsPlot data={stats.order_stats} />
          : <TimedCountsTable data={stats.order_stats} />
        }
      </Row>
      { stats.registered_customers ? ( <Row> <h1> Registered Customers </h1> </Row> ) : <div/> }
      {
        stats.registered_customers
          ? (<Row> <TimedCountsTable data={stats.registered_customers} /> </Row>)
          : <div/>
      }
      { stats.order_durations ? ( <Row> <h1>Order Durations (minutes) </h1> </Row> ) : <div/> }
      {
        stats.order_durations
          ? <Row> <TimedRankStatsTable data={divideTimedRankStats(stats.order_durations, 60)} /> </Row>
          : <div/>
      }
      { stats.pickup_time_deltas ? ( <Row> <h1>Pickup Time Deltas (minutes) </h1> </Row> ) : <div/> }
      {
        stats.pickup_time_deltas
          ? <Row> <TimedRankStatsTable data={divideTimedRankStats(stats.pickup_time_deltas, 60)} /> </Row>
          : <div/>
      }
      <Row>
        <Col span={2}>
          <h1>Items</h1>
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={() => exportToCsv({filename: "item_stats.csv", data: stats.item_stats})} >
            Download table
          </Button>
        </Col>
      </Row>
      <Row>
        { /* too many items to plot, only show table */ }
        <TimedCountsTable data={stats.item_stats} />
      </Row>
      { /* TODO(kku): show workstation processing time again once we calculate it again.
      <Row>
        <h1>Average Workstation Processing Time (minutes)</h1>
      </Row>
      <Row>
        {
          display_plot
          ? <TimedCountsPlot data={divideTimedCounts(stats.workstation_process_avg, 60)} />
          : <TimedCountsTable data={divideTimedCounts(stats.workstation_process_avg, 60)} />
        }
      </Row>
      */ }
    </div>
  );
}

// Convert tips to TimedCounts so we can reuse TimedCountsTable.
function tipsToTimedCounts(tips?: Counts): TimedCounts {
  if (!tips) {
    return {};
  }
  let tc: TimedCounts = {};
  Object.entries(tips).forEach(([ts, tip]) => {
    tc[ts] = { "Amount $": tip / 100 };
  });
  return tc;
};
