import React, { useEffect, useState } from "react";
import { Button, Card, Empty } from "antd";

import styles from "./css/StationCol.module.css";

export default ({
  elems,
  renderElem,
  renderModal,
  heading,
  showHeading,
  headingAction,
  bgColor,
  colCardPadding,
  elemsMaxHeight,
  emptyDescription,
  renderLeftButton,
  renderRightButton,
}) => {
  const [showElemModal, setShowElemModal] = useState(null);

  const elemIDs = new Set();
  elems.forEach((elem) => {
    elemIDs.add(elem.id);
  });

  useEffect(() => {
    setShowElemModal((prev) => {
      if (prev && !elemIDs.has(prev)) {
        // Clear show modal state if elem is no longer in this column
        return null;
      }
      return prev;
    });
  }, [elemIDs]);

  return (
    <div>
      <Card
        bodyStyle={
          colCardPadding
            ? { backgroundColor: bgColor, padding: colCardPadding }
            : { backgroundColor: bgColor }
        }
        bordered={false}
        className={styles.colCard}
      >
        <div
          className={styles.heading}
          style={showHeading || headingAction ? { marginBottom: 8 } : {}}
        >
          {showHeading && (
            <span>
              {heading} {elems && <span>({elems.length})</span>}
            </span>
          )}
          {headingAction}
        </div>
        {elems && elems.length > 0 ? (
          <div
            className={styles.elems}
            style={elemsMaxHeight ? { maxHeight: elemsMaxHeight } : {}}
          >
            {elems.map((elem, elemIndex) => {
              const onModalCancel = () => {
                setShowElemModal(null);
              };
              const modalFooter = (
                <Button
                  key="cancel"
                  onClick={onModalCancel}
                  className={styles.cancel}
                >
                  取消
                </Button>
              );
              return (
                <Card
                  className={styles.elemCard}
                  key={elem.id}
                  bordered={false}
                  bodyStyle={{
                    display: "flex",
                    alignItems: "stretch",
                    padding: 0,
                    fontSize: 16,
                  }}
                >
                  {renderLeftButton && renderLeftButton(elem.id, elemIndex)}
                  <div
                    className={styles.elemInfo}
                    onClick={() => {
                      setShowElemModal(elem.id);
                    }}
                  >
                    {renderElem(elem)}
                  </div>
                  {renderModal({
                    elem,
                    visible: showElemModal === elem.id,
                    onCancel: onModalCancel,
                    footer: modalFooter,
                    style: { maxWidth: "90%", userSelect: "none" },
                    wrapClassName: styles.modalWrap,
                  })}
                  {renderRightButton && renderRightButton(elem, elemIndex)}
                </Card>
              );
            })}
          </div>
        ) : (
          <Card>
            <Empty description={emptyDescription} />
          </Card>
        )}
      </Card>
    </div>
  );
};
