import React, { useState } from "react";
import { Alert, Form, Input, Button, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { isLoggedIn } from "./Auth";
import { getFetch, API_URL } from "./Util";
import styles from "./css/Login.module.css";

const { Title } = Typography;

export default ({ history, location }) => {
  const [loginError, setLoginError] = useState("");

  const loginRedirect = () => {
    let { from } = location.state || { from: { pathname: "/" } };
    history.replace(from);
  };

  if (isLoggedIn()) {
    loginRedirect();
  }

  const onFinish = (values) => {
    sessionStorage.setItem("username", values.username);
    sessionStorage.setItem("password", values.password);

    const handleResp = (restaurants) => {
      sessionStorage.setItem("ticketrails_logged_in", "true");
      if (restaurants.length > 0) {
        // HACK: use only first restaurant
        const restaurant = restaurants[0];
        sessionStorage.setItem("restaurant_id", restaurant.id);
        if (restaurant.roles) {
          sessionStorage.setItem("ticketrails_roles", restaurant.roles.join(","));
        }

        loginRedirect();
      } else {
        setLoginError("No restaurants for this account");
      }
    };
    const handleError = (err) => setLoginError("Failed to login");
    getFetch(`${API_URL}/v1/u/restaurants`, handleResp, handleError);
  };

  return (
    <div className={styles.login}>
      <Title className={styles.title}>Ticket Rails</Title>
      <Form onFinish={onFinish}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "請輸入帳號" }]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="帳號"
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "請輸入密碼" }]}
        >
          <Input
            prefix={<LockOutlined />}
            type="password"
            placeholder="密碼"
            autoComplete="current-password"
          />
        </Form.Item>
        {loginError && (
          <Alert
            message={loginError}
            type="error"
            showIcon
            className={styles.error}
          />
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
