import React from 'react'; 

import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Upload,
  notification,
} from "antd";
import ImgCrop from 'antd-img-crop';

import {
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { Price } from '../Price';
import { API_URL, getCredentials } from "../Util";
import DynamicImg from "../DynamicImg";
import { fetchT } from "../TypedUtil";
import {
  CreateItemSchemaResponse,
  UpdateItemSchemaResponse,
} from "../types/api";
import {
  ActionSchema,
  ItemModificationSchema,
  ItemSchema,
  ImageManifest,
  MenuType,
} from "../types/model";

const { Dragger } = Upload;

type ItemSchemaFormProps = {
  restaurant_id: string;

  item_schema: ItemSchema;

  menu_type: MenuType;

  // True if this is a new schema being added instead of updating an existing
  // one.
  is_new: boolean;

  // All action schemas, not just ones already assigned to this item schema.
  action_schemas: ActionSchema[];

  // All modification schemas, not just ones already assigned to this item
  // schema.
  item_modification_schemas: ItemModificationSchema[];

  on_update_success: (new_schema: ItemSchema) => void;
}

export const ItemSchemaForm = (props: ItemSchemaFormProps) => {
  const [saveLoading, setSaveLoading] = React.useState(false);

  const [shippable, setShippable] = React.useState<boolean>(props.item_schema.shippable);

  const handleSave = (formValues: Omit<ItemSchema, 'price'> & { priceString : string }) => {
    setSaveLoading(true);

    const schema: ItemSchema = {
      ...formValues,
      // Convert price back into cents.
      price: Price.fromString(formValues.priceString).toCents(),
    };

    if (props.is_new) {
      fetchT<CreateItemSchemaResponse>(
        "POST",
        `${API_URL}/v1/r/${props.restaurant_id}/item_schemas`,
        { item_schema: schema },
        (result: CreateItemSchemaResponse) => {
          props.on_update_success(schema);

          notification['success']({
            message: 'Success',
            description: `Created ${schema.name} successfully!`,
          });

          setSaveLoading(false);
        },
        (err: any) => {
          setSaveLoading(false);

          let description: string = "";
          try {
            description = JSON.parse(err)["message"];
          } catch(e) {
            description = "發生錯誤";
          }

          notification['error']({
            message: 'Error',
            description: description,
          });
        },
      );
    } else {
      fetchT<UpdateItemSchemaResponse>(
        "PUT",
        `${API_URL}/v1/r/${props.restaurant_id}/item_schemas/${props.item_schema.id}`,
        schema,
        (result: UpdateItemSchemaResponse) => {
          props.on_update_success(schema);

          notification['success']({
            message: 'Success',
            description: `Updated ${schema.name} successfully!`,
          });

          setSaveLoading(false);
        },
        (err: any) => {
          setSaveLoading(false);

          let description: string = "";
          try {
            description = JSON.parse(err)["message"];
          } catch(e) {
            description = "發生錯誤";
          }

          notification['error']({
            message: 'Error',
            description: description,
          });
        },
      );
    }
  };

  // Convert item schema to something more friendly for our form.
  const initial_schema = { ...props.item_schema, priceString: '' };
  {
    // We store price in cents, convert to dollars for display.
    initial_schema.priceString = Price.fromCents(initial_schema.price).toString();

    // Only use id when modifying action and modification schema relationships.
    initial_schema.action_schema_ids = []
    if (initial_schema.action_schemas) {
      initial_schema.action_schema_ids = initial_schema.action_schemas.map((action) => action.id);
    }

    initial_schema.modification_schema_ids = [];
    if (initial_schema.modification_schemas) {
      initial_schema.modification_schema_ids = initial_schema.modification_schemas.map((mod) => mod.id);
    }
  }

  const action_schema_choices = props.action_schemas.map((action) => (
    <Select.Option key={action.id} value={action.id}>
      <span>{action.name}</span>
    </Select.Option>
  ));
  const item_modification_schema_choices = props.item_modification_schemas.map((mod) => (
    <Select.Option key={mod.id} value={mod.id}>
      <span>{mod.description}</span>
    </Select.Option>
  ));

  const [form] = Form.useForm();
  const formElem = (
    <Form
      form={form}
      labelCol={{ span: 10 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      initialValues={initial_schema}
      colon={false}
      onFinish={handleSave}
    >
      <Form.Item
        name="id"
        hidden={true}
      />
      <Form.Item
        label={"菜單品名"}
        name="name"
        rules={[
          {
            required: true,
            message: "Name is required.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"收據品名"}
        name="alternate_name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Kitchen Chinese Name"}
        name={["intl_name", "zh"]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Kitchen Spanish Name"}
        name={["intl_name", "es"]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Kitchen English Name"}
        name={["intl_name", "en"]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Key (e.g. R3, A4)"}
        name="pos_key"
        hidden={props.menu_type !== 'dine_in'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Price"}
        name="priceString"
        rules={[
          {
            required: true,
            message: "Price is required.",
          },
        ]}
      >
        <InputNumber
          min={0}
          step={0.01}
          precision={2}
          stringMode={true}
        />
      </Form.Item>
      <Form.Item
        label={"Points"}
        name="points"
      >
        <InputNumber
          min={0}
          step={1}
          precision={0}
        />
      </Form.Item>
      <Form.Item
        label={"Tax Free"}
        name="tax_free"
        valuePropName="checked"
      >
        <Switch checkedChildren="YES" unCheckedChildren="NO" />
      </Form.Item>
      <Form.Item
        label={"Shippable"}
        name="shippable"
        valuePropName="checked"
      >
        <Switch checkedChildren="YES" unCheckedChildren="NO" onChange={setShippable} />
      </Form.Item>
      <Form.Item
        label={"Weight (grams)"}
        name="weight"
        rules={[
          {
            required: shippable,
            message: "Weight is required for shippable item.",
          },
        ]}
      >
        <InputNumber
          min={0}
          step={1}
          precision={0}
        />
      </Form.Item>
      <Form.Item
        name="image_url"
        hidden={true}
      />
      <Form.Item
        name="image_manifest"
        hidden={true}
      />
      <Form.Item
        label={"Print Label (印標籤貼紙)"}
        name="print_label"
        valuePropName="checked"
      >
        <Switch checkedChildren="YES" unCheckedChildren="NO" />
      </Form.Item>
      <Form.Item
        label={"Sold Out"}
        name="sold_out"
        valuePropName="checked"
      >
        <Switch checkedChildren="YES" unCheckedChildren="NO" />
      </Form.Item>
      <Form.Item
        label={"Featured"}
        name="featured"
        valuePropName="checked"
      >
        <Switch checkedChildren="YES" unCheckedChildren="NO" />
      </Form.Item>
      <Form.Item
        name="item_schema_group_id"
        hidden={true}
      />
      <Form.Item
        label={"Actions (工作區)"}
        name="action_schema_ids"
      >
        <Select
          mode="multiple"
          allowClear={true}
          showSearch={false}
        >
          {action_schema_choices}
        </Select>
      </Form.Item>
      <Form.Item
        label={"Modifications (客制化選項)"}
        name="modification_schema_ids"
      >
        <Select
          mode="multiple"
          allowClear={true}
          showSearch={false}
        >
          {item_modification_schema_choices}
        </Select>
      </Form.Item>
      <Form.Item
        label={"Notes"}
        name="notes"
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          block
          htmlType="submit"
          loading={saveLoading}
          disabled={saveLoading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );

  // Image upload states.
  const [imgLoading, setImgLoading] = React.useState<boolean>(false);
  const [imgManifest, setImgManifest] = React.useState<ImageManifest | undefined>(props.item_schema.image_manifest);
  const [imgUploadErr, setImgUploadErr] = React.useState<string | null>(null);

  // Handle legacy item schemas.
  if (imgManifest === undefined && props.item_schema.image_url !== undefined && props.item_schema.image_url !== "") {
    setImgManifest({
      default: {
        url: props.item_schema.image_url,
        type: "image/jpeg",
        width: 0,
        height: 0,
      }
    })
  }

  const uploadButton = (
    <div>
      {imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload Image</div>
    </div>
  );
  const uploaderBody = imgManifest !== undefined && !imgLoading
    ? <DynamicImg manifest={imgManifest} alt="item_image" style={{ width: "100%" }} />
    : uploadButton

  const handleUploadChange = (info: any) => {
    if (info.file.status === "uploading") {
      setImgLoading(true);
    } else if (info.file.status === "done") {
      setImgLoading(false);
      setImgManifest(info.file.response.manifest);
      form.setFieldsValue({
        "image_url": info.file.response.s3_url,
        "image_manifest": info.file.response.manifest,
      });
    } else if (info.file.status === "error") {
      setImgLoading(false);
      setImgUploadErr(`Failed to upload image: ${info.file.response}`);
    }
  };

  return (
    <div>
      <ImgCrop
        aspect={3/2}
        grid={true}
        quality={1}
        rotate={true}
        minZoom={0.1}
        fillColor="black"
      >
        <Dragger
          name="img"
          headers={{ Authorization: `Basic ${getCredentials()}` }}
          accept=".jpg,.jpeg,.png,.gif,.webp,image/jpeg,image/png,image/gif,image/webp"
          showUploadList={false}
          action={`${API_URL}/v1/r/${props.restaurant_id}/user_content/item_images`}
          beforeUpload={() => { setImgUploadErr(null); setImgLoading(true); return true; }}
          onChange={handleUploadChange}
        >
          {uploaderBody}
        </Dragger>
      </ImgCrop>
      {
        imgUploadErr
        ? <div style={{ color: "red" }}>{imgUploadErr}</div>
        : <div />
      }
      <br/>
      {formElem}
    </div>
  );
}
