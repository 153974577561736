import React, { Fragment, useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Card, Col, Row, Tabs, Tag, Input, notification } from "antd";
const { Search } = Input;
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';

import Page from "./Page";
import OrderDetails from "./OrderDetails";

import { getFetch, API_URL } from "./Util";
import styles from "./css/ControlStation.module.css";

const localTime = (t) => {
  return t.toLocaleString("en-ZA", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
};

export default () => {
  const restaurantID = sessionStorage.getItem("restaurant_id");
  if (!restaurantID) {
    const location = useLocation();
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  const [orderDate, setOrderDate] = useState(null);
  const [orders, setOrders] = useState([]);
  const onSearch = (order_name) => {
    let url = `${API_URL}/v1/r/${restaurantID}/pos_order_names/${order_name}`;
    if (orderDate) {
      url += "?ts=" + encodeURIComponent(orderDate.toISOString(true));
    }

    getFetch(
      url,
      (result) => {
        if (result.hasOwnProperty('orders') && result.orders && result.orders.length > 0) {
          setOrders(result.orders);
        } else {
          setOrders([]);
          notification.open({
            message: '沒有結果',
            description: '',
            onClick: () => {},
          });
        }
      },
      (err) => {
        notification['error']({
          message: 'Error',
          description: '發生錯誤',
          onClick: () => {},
        });
      },
    );
  };

  const orderCard = (order) => {
    try {
      return (
        <Card key={order.id} title={order.pos_order_name + ` (${order.pos_order_type})`}>
          { order.create_time && (<h3>{"Order Time 下單時間: " + localTime(new Date(order.create_time))}</h3>) }
          { order.start_time && (<h3>{"Start Time 開始時間: " + localTime(new Date(order.start_time))}</h3>) }
          { order.done_time && (<h3>{"Done Time 完成時間: " + localTime(new Date(order.done_time))}</h3>) }
          <Row>
            { order.paid ? <h3 style={{color: "green"}}>PAID 已付款</h3> : <h3 style={{color: "red"}}>NOT PAID 未付款</h3> }
          </Row>
          {
            order.customer && (
              <Row>
                <div>
                  <p>Name: {order.customer.name}</p>
                  <p>Email: {order.customer.email}</p>
                  <p>Phone: {order.customer.phone}</p>
                </div>
              </Row>
            )
          }
          <Row>
            <OrderDetails
              order={order}
              pending={false}
              restaurantID={restaurantID}
            />
          </Row>
        </Card>
      );
    } catch (err) {
      console.log("error displaying order search result", err);
      return "";
    }
  };

  let cards = orders.map(orderCard);

  return (
    <Page title={`訂單查尋`}>
      <Row gutter={[8,8]}>
        <Col span={18}>
          <Search
            placeholder="input search text"
            allowClear
            enterButton="Enter"
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={6}>
          <MuiDatePicker
            value={orderDate}
            helperText="訂單日期(可不選,默認最近10筆訂單)"
            format={"ddd, MMM DD"}
            size="large"
            autoOk={true}
            disableFuture={true}
            clearable={true}
            onChange={setOrderDate}
          />
        </Col>
      </Row>
      <div>
        {cards}
      </div>
    </Page>
  );
};
