import React from 'react'; 
import moment from "moment";

import {
  Spin,
  Table,
  Tabs,
  Modal,
  Divider,
  Typography,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { API_URL } from "../Util";
import { getFetchT } from "../TypedUtil";
import { NotifyError } from "../NotifyError";
import {
  Customer,
  PointHistory,
  PointCredit,
  PointDebit,
} from "../types/model";
import { PointCreditDeleteButton } from "./PointCreditDeleteButton";

import styles from "../css/PointHistory.module.css";
import { Redirect, useLocation } from 'react-router-dom';

const { TabPane } = Tabs;
const { Title } = Typography;

type PointHistoryTableProp = {
  customer: Customer;
  restaurant_id: string;

  // A state that is updated to force this component to refresh.
  version: number;
}

export const PointHistoryTable = (props: PointHistoryTableProp) => {
  const [pointHistory, setPointHistory] = React.useState<PointHistory | undefined>(undefined);

  const fetchPointHistory = () => {
    const url = `${API_URL}/v1/r/${props.restaurant_id}/customers/${props.customer.id}/points`;
    getFetchT<PointHistory>(
      url,
      setPointHistory,
      (err: any) => {
        NotifyError("無法取得點數紀錄", err);
      }
    );
  };
  React.useEffect(fetchPointHistory, [props.customer, props.version]);

  if (pointHistory === undefined) {
    return <Spin size="large" />;
  }

  const unexpired = (pointHistory.credits ?? []).filter(c => !creditExpired(c));
  const expired = (pointHistory.credits ?? []).filter(c => creditExpired(c));

  const debits = new Map<string, PointDebit>(
    (pointHistory.debits ?? []).map(d => [d.id, d])
  );

  const refresh = () => {
    setPointHistory(undefined);
    fetchPointHistory();
  };

  return (
    <>
      <Title level={5}>Balance: {getBalance(pointHistory.credits ?? [])}</Title>
      <Tabs defaultActiveKey="active">
        <TabPane tab="Active" key="active">
          <PointsTable
            credits={unexpired}
            debits={debits}
            restaurantID={props.restaurant_id}
            refresh={refresh}
          />
        </TabPane>
        <TabPane tab="Expired" key="expired">
          <PointsTable
            credits={expired}
            debits={debits}
            restaurantID={props.restaurant_id}
            refresh={refresh}
          />
        </TabPane>
      </Tabs>
    </>
  );
}

function creditBalance(c: PointCredit): number {
  let total = c.amount;
  for (const redeem of c.redeems ?? []) {
    total -= redeem.amount;
  }
  return total;
}

function creditExpired(c: PointCredit): boolean {
  if (c.expire_time === undefined) {
    return false;
  }
  return moment(c.expire_time).startOf('day').isBefore(moment().startOf('day'));
}

function getBalance(credits: PointCredit[]): number {
  // Only consider credits that have not expired.
  const unexpired = credits.filter(c => !creditExpired(c));
  return unexpired.map(creditBalance).reduce((prev, curr) => prev + curr, 0);
}

function PointsTable({
  credits,
  debits,
  restaurantID,
  refresh,
}: {
  credits: PointCredit[],
  debits: Map<string, PointDebit>,
  restaurantID: string,
  refresh: () => void,
}) {
  // If a credit is selected, show its detail modal.
  const [selectedCredit, setSelectedCredit] = React.useState<PointCredit | null>(null);

  // Prevent page scroll when selected credit modal is open.
  React.useEffect(() => {
    if (selectedCredit === null) {
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [selectedCredit]);

  // Sort by most recent first.
  credits.sort((p1, p2) => {
    const t1 = moment(p1.create_time);
    const t2 = moment(p2.create_time);
    if (t1.isAfter(t2)) {
      return -1;
    }
    return 1;
  })

  // Limit to 3 columns to fit on mobile screen.
  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Credit',
      key: 'credit',
      render: (text: string, record: PointCredit, index: number) => {
        return <span style={{ color: "green" }}>+{record.amount}</span>;
      },
    },
    {
      title: 'Debit',
      key: 'debit',
      render: (text: string, record: PointCredit, index: number) => {
        const debits = record.redeems?.map(r => r.amount)?.reduce((a, b) => a + b, 0) ?? 0;
        if (debits > 0) {
          return <span style={{ color: "red" }}>-{debits}</span>;
        }
        return <span>{0}</span>;
      },
    },
    {
      title: 'Expiry',
      key: 'expire_time',
      render: (text: string, record: PointCredit, index: number) => {
        if (record.expire_time === undefined) {
          return undefined;
        }
        return moment(record.expire_time).format("YYYY/MM/DD");
      },
    },
    {
      title: '',
      key: 'details',
      render: (text: string, record: { amount: number }, index: number) => {
        return <QuestionCircleOutlined />;
      },
    },
  ];

  return (
    <div>
      <RedeemDetailModal
        debits={debits}
        credit={selectedCredit}
        setCredit={setSelectedCredit}
        onDeleteSuccess={refresh}
        restaurantID={restaurantID}
      />
      <Table
        rowKey={r => r.id}
        rowClassName={r => {
          if (selectedCredit?.id === r.id) {
            return styles.pointsTableRowSelected;
          }
          return styles.pointsTableRow;
        }}
        dataSource={credits}
        onRow={(record, rowIndex) => {
          return {
            onClick: ev => setSelectedCredit(record),
          };
        }}
        columns={columns}
      />
    </div>
  );
}

// Gives detailed information of redeems for each row in the PointsTable.
function RedeemDetailModal({
  debits,
  credit,
  setCredit,
  onDeleteSuccess,
  restaurantID,
}: {
  debits: Map<string, PointDebit>,
  credit: PointCredit | null,
  setCredit: React.Dispatch<React.SetStateAction<PointCredit | null>>,
  onDeleteSuccess: () => void,
  restaurantID: string,
}) {
  // Limit to 3 columns to fit on mobile screen.
  const columns = [
    {
      title: 'Date',
      key: 'create_time',
      render: (text: string, record: { create_time: string }, index: number) => {
        return moment(record.create_time).format("YYYY/MM/DD");
      },
    },
    {
      title: 'Description',
      key: 'description',
      render: (text: string, record: { point_debit_id: string }, index: number) => {
        return debits.get(record.point_debit_id)?.description ?? '';
      },
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (text: string, record: { amount: number }, index: number) => {
        return <span style={{ color: "red" }}>-{record.amount}</span>;
      },
    },
  ];

  if (credit === null) {
    return null;
  }

  return (
    <Modal visible={true} title={'Point Details'} footer={null} onCancel={() => setCredit(null)}>
      {
        (credit.redeems ?? []).length == 0 && (
          <PointCreditDeleteButton
              id={credit.id}
              restaurant_id={restaurantID}
              on_delete_success={onDeleteSuccess}
          />
        )
      }
      <div>{credit.description}</div>
      <div>Earned On / 領取日: {moment(credit.create_time).format("YYYY/MM/DD")}</div>
      { credit.expire_time && <div>Expiry / 過期日: {moment(credit.expire_time).format("YYYY/MM/DD")}</div> }
      <Divider />
      <div>Amount Earned / 領取數量: {credit.amount}</div>
      <div>Amount Redeemed / 已兌換數量: {credit.amount - creditBalance(credit)}</div>
      <div>Balance / 餘額: {creditBalance(credit)}</div>
      <Divider />
      <Title level={5}>Redeem History | 兌換紀錄</Title>
      <Table
        rowKey={r => r.id}
        dataSource={credit.redeems}
        columns={columns}
        pagination={false}
      />
    </Modal>
  );
}
