import React, { useEffect, useState } from "react";
import { Button, Modal, Radio, Space, message } from "antd";
import { TranslationOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useTranslation } from 'react-i18next';

import { postFetch, API_URL } from "./Util";

// Selects language.
// setLang: (IETF language code: string) => void
export default ({}) => {
  const { t, i18n } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const onSelect = (e) => {
    sessionStorage.setItem("user_pref:lang", e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div>
      <Button
        type="primary"
        size="large"
        icon={<TranslationOutlined />}
        onClick={() => setShowModal(true)}
      />
      <Modal
        title="Select Language | 選擇語言 | Seleccionar el Idioma"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowModal(false)} size="large">
            OK
          </Button>
        ]}
      >
        <Radio.Group onChange={onSelect} value={i18n.language} size="large" buttonStyle="solid">
          <Space space="large">
            <Radio.Button value={"zh"}>中文</Radio.Button> 
            <Radio.Button value={"en"}>English</Radio.Button> 
            <Radio.Button value={"es"}>Español</Radio.Button> 
          </Space>
        </Radio.Group>
      </Modal>
    </div>
  );
};
