import React from 'react'; 

import moment from "moment";

import { TimedCounts } from "../types/api";

export type FlatTimedCounts = {
  // Timestamp in RFC 3339 format.
  xxx_tr_ts: string;

  // Key for use on the x-axis.
  xxx_tr_x_key: string;

  // Individual groups to plot for this x-coordinate.
  // Note: we need the string type in order for typescript to be happy about
  // other fields in the object having string type.
  [y_tag: string]: number | string;
};

export const ToFlatTimedCounts = (data: TimedCounts): [string[], FlatTimedCounts[]] => {
  let flats: FlatTimedCounts[] = [];
  let key_set = new Set<string>();

  Object.entries(data).forEach(([ts, counts]) => {
    let point: FlatTimedCounts = {
      xxx_tr_ts: ts,
      xxx_tr_x_key: moment(ts).format("MM/DD"),
    };

    Object.entries(counts).forEach(([key, v]) => {
      key_set.add(key);
      point[key] = v;
    });

    flats.push(point);
  });

  // Sort flats by timestamp.
  flats.sort((a: FlatTimedCounts, b: FlatTimedCounts) => {
    return a.xxx_tr_ts.localeCompare(b.xxx_tr_ts);
  });

  const data_keys = Array.from(key_set);
  data_keys.sort();

  return [data_keys, flats];
};
