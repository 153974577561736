import React from 'react'; 

import {
  Button,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Table,
  Tabs,
  notification,
  Typography
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import { API_URL } from "../Util";
import { assertNever, getFetchT } from "../TypedUtil";
import {
  GetMenuResponse,
  GetActionSchemasResponse,
  GetItemModificationSchemasResponse,
} from "../types/api";
import {
  ActionSchema,
  ItemModificationSchema,
  ItemSchema,
  ItemSchemaGroup,
  MenuType,
} from "../types/model";
import { ItemSchemaModal } from "./ItemSchemaModal";
import { ItemSchemaDeleteButton } from "./ItemSchemaDeleteButton";

const { Title } = Typography;

type ItemSchemaGroupListProps = {
  restaurant_id: string;
}

export const ItemSchemaGroupList = (props: ItemSchemaGroupListProps) => {
  const [selectedMenuType, setSelectedMenuType] = React.useState<string>("dine_in");
  const [groups, setGroups] = React.useState<ItemSchemaGroup[] | null >(null);
  const [action_schemas, setActionSchemas] = React.useState<ActionSchema[] | null>(null);
  const [mod_schemas, setModSchemas] = React.useState<ItemModificationSchema[] | null>(null);

  const refreshMenu = () => {
    getFetchT<GetMenuResponse>(
      `${API_URL}/v1/public/r/${props.restaurant_id}/menu`,
      (result: GetMenuResponse) => {
        setGroups(result.item_schema_groups);
      },
      (err) => {
        notification['error']({
          message: 'Error',
          description: '發生錯誤',
        });
      },
    );
  };

  // Load the menu.
  React.useEffect(refreshMenu, [props.restaurant_id]);

  // Load action and modification schemas.
  React.useEffect(() => {
    getFetchT<GetActionSchemasResponse>(
      `${API_URL}/v1/r/${props.restaurant_id}/action_schemas`,
      (result: GetActionSchemasResponse) => {
        setActionSchemas(result.schemas);
      },
      (err) => {
        notification['error']({
          message: 'Error',
          description: '發生錯誤',
        });
      },
    );
  }, [props.restaurant_id]);

  React.useEffect(() => {
    getFetchT<GetItemModificationSchemasResponse>(
      `${API_URL}/v1/r/${props.restaurant_id}/item_modification_schemas`,
      (result: GetItemModificationSchemasResponse) => {
        setModSchemas(result.schemas);
      },
      (err) => {
        notification['error']({
          message: 'Error',
          description: '發生錯誤',
        });
      },
    );
  }, [props.restaurant_id]);

  if (groups === null || action_schemas === null || mod_schemas === null) {
    // Still loading
    return (
      <Spin size="large"/>
    );
  }

  const itemSchemaGroupTable = (group: ItemSchemaGroup, menuType: MenuType) => {
    const columns: any = [
      {
        title: "Name",
        dataIndex: "name",
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend"],
        sorter: (a: ItemSchema, b: ItemSchema) => {
          return a.name.localeCompare(b.name);
        },
        render: (text: string, record: ItemSchema) => (
          <ItemSchemaModal
            restaurant_id={props.restaurant_id}
            menu_type={menuType}
            item_schema={record}
            is_new={false}
            action_schemas={action_schemas}
            item_modification_schemas={mod_schemas}
            on_update_success={(new_schema: ItemSchema) => {
              // Set groups to null to show loading icon again.
              setGroups(null);
              refreshMenu();
            }}
          />
        ),
      },
      {
        render: (text: string, record: ItemSchema) => (
          <ItemSchemaDeleteButton
            restaurant_id={props.restaurant_id}
            item_schema={record}
            on_delete_success={() => {
              // Set groups to null to show loading icon again.
              setGroups(null);
              refreshMenu();
            }}
          />
        ),
      },
    ];

    // Empty schema for adding an item to this group.
    const new_schema_template: ItemSchema = {
      // The new item belongs to the current group.
      item_schema_group_id: group.id,

      // Set dummy values for required fields.
      // We use a dummy value for the id since the backend cannot deserialize
      // empty string as a trid. The backend will replace this with a random ID.
      id: "its_Temporary_IdForNewItem",
      name: "",
      price: 0,
      tax_free: false,
      shippable: false,

      // All other attributes are empty, waiting to be set by the user.
    };

    return (
      <div key={`group-${group.id}`}>
        <Divider />
        <Row>
          <Col span={20}><h3>{group.name}</h3></Col>
          <Col span={4}>
            <ItemSchemaModal
              restaurant_id={props.restaurant_id}
              item_schema={new_schema_template}
              menu_type={menuType} 
              is_new={true}
              action_schemas={action_schemas}
              item_modification_schemas={mod_schemas}
              on_update_success={(new_schema: ItemSchema) => {
                // Set groups to null to show loading icon again.
                setGroups(null);
                refreshMenu();
              }}
            />
          </Col>
        </Row>
        <br/>
        <Table
          columns={columns}
          dataSource={group.item_schemas}
          rowKey={(record: ItemSchema) => `row-${record.id}`}
        />
      </div>
    );
  };

  const menuTypes: ReadonlyArray<MenuType> = [...new Set(groups.map(g => g.menu_type))]
  return (
    <div>
      <Title level={4}>Select Menu Type / 選擇菜單類型</Title>
      <Tabs
        defaultActiveKey={"dine_in"}
        onChange={(newValue) => {
          if (newValue != null) {
            setSelectedMenuType(newValue);
          }
        }}
      >
        {menuTypes.map((menuType) => {
          let displayName: string = "";
          switch (menuType) {
            case 'dine_in':
              displayName = "Dine In / Pickup";
              break;
            case 'large_volume':
              displayName = "Group Order";
              break;
            default:
              assertNever(menuType);
          }

          return (
            <Tabs.TabPane tab={displayName} key={menuType}>
              {(groups.filter(group => group.menu_type === menuType)).map((group) => itemSchemaGroupTable(group, menuType))}
            </Tabs.TabPane>
          );
        }
        )}
      </Tabs>
    </div>
  )
}
