import React from "react";
import { Modal } from "antd";

import StationCol from "./StationCol";

import styles from "./css/DoneOrderCol.module.css";

export default ({
  orders,
  renderOrder,
  renderOrderTitle,
  renderOrderDetails,
  showHeading,
  elemsMaxHeight,
}) => {
  return (
    <StationCol
      elems={orders}
      emptyDescription="No orders"
      heading="完成"
      showHeading={showHeading}
      bgColor="rgba(53, 134, 0, 0.7)"
      elemsMaxHeight={elemsMaxHeight}
      renderElem={renderOrder}
      renderModal={({
        elem,
        visible,
        onCancel,
        footer,
        style,
        wrapClassName,
      }) => {
        return (
          <Modal
            title={renderOrderTitle ? renderOrderTitle(elem) : null}
            visible={visible}
            onCancel={onCancel}
            footer={footer}
            style={style}
            wrapClassName={`${wrapClassName} ${styles.modalWrap}`}
          >
            {renderOrderDetails(elem)}
          </Modal>
        );
      }}
    />
  );
};
