import React from 'react'; 

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ColorHash from "color-hash";

import { TimedCounts } from "../types/api";
import { ToFlatTimedCounts } from "./TimedCountsUtil";

export type TimedCountsPlotProps = {
  data: TimedCounts;
};

// Plots data sliced by time (x-axis), with multiple labels on y-axis.
export const TimedCountsPlot = (props: TimedCountsPlotProps) => {
  const [flat_keys, flats] = ToFlatTimedCounts(props.data);

  // Assign color to each bar based on key.
  const colorHash = new ColorHash();

  return (
    <ResponsiveContainer width="95%" height={300}>
      <LineChart data={flats} >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="xxx_tr_x_key" />
        <YAxis/>
        <Tooltip/>
        <Legend />
        {
          flat_keys.map((key: string) => {
            return <Line dataKey={key} type="monotone" stroke={colorHash.hex(key)} />
          })
        }
      </LineChart>
    </ResponsiveContainer>
  );
};
