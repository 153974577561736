import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

import './i18n';
import './css/index.css';

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://7beec6cd952949c7b443b0ac32be57ea@o1261310.ingest.sentry.io/4504748341002240",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.001,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
