import React from 'react'; 

import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import {
  ActionSchema,
  ItemModificationSchema,
  ItemSchema,
  MenuType,
} from "../types/model";
import { ItemSchemaForm } from "./ItemSchemaForm";

type ItemSchemaModalProps = {
  restaurant_id: string;
  item_schema: ItemSchema;
  menu_type: MenuType;

  // True if this is a new schema being added instead of updating an existing
  // one.
  is_new: boolean;

  // All action schemas, not just ones already assigned to this item schema.
  action_schemas: ActionSchema[];

  // All modification schemas, not just ones already assigned to this item
  // schema.
  item_modification_schemas: ItemModificationSchema[];

  on_update_success: (new_schema: ItemSchema) => void;
}

export const ItemSchemaModal = (props: ItemSchemaModalProps) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <div>
      <Modal
        visible={visible}
        title={props.is_new ? "New Item" : props.item_schema.name}
        onCancel={() => setVisible(false)}
        destroyOnClose={true}
        footer={null}
      >
        <ItemSchemaForm {...props} />
      </Modal>
      {
        props.is_new
        ? (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setVisible(true)}
            style={{ float: "right" }}
          >
            New Item
          </Button>
        )
        : <a onClick={() => setVisible(true)}>{props.item_schema.name}</a>
      }
    </div>
  );
}
