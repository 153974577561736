import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Space } from "antd";
import { ReloadOutlined, LeftOutlined, LogoutOutlined } from "@ant-design/icons";

import { useTranslation } from 'react-i18next';

import ErrorBoundary from "./ErrorBoundary";
import SetLangButton from "./SetLangButton";

import { postFetch, API_URL } from "./Util";
import styles from "./css/Page.module.css";

export default ({ headerClassName, title, noBack, children }) => {
  const history = useHistory();

  const { t, i18n } = useTranslation();

  // Init saved language preference.
  useEffect(() => {
    const lang = sessionStorage.getItem("user_pref:lang");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  // Check whether to reload to get new UI version every 5 minutes.
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TIME) {
      const buildTime = new Date(process.env.REACT_APP_BUILD_TIME);

      const interval = setInterval(() => {
        postFetch(
          `${API_URL}/v1/ui_version`,
          { build_time: buildTime },
          (resp) => {
            if (resp.version_ok !== undefined && resp.version_ok === false) {
              window.location.reload(true);
            }
          }
        );
      }, 5 * 60 * 1000);

      return () => { clearInterval(interval) };
    }
  }, []);

  return (
    <div>
      <div
        className={
          headerClassName
            ? `${styles.header} ${headerClassName}`
            : styles.header
        }
      >
        <a href="/" className={styles.ticketRails}>
          {!noBack && <LeftOutlined className={styles.back} />}
          <span>Ticket Rails</span>
        </a>
        {title && (
          <span className={styles.title}>
            <span className={styles.titleSpacer}>|</span>
            <span>{title}</span>
          </span>
        )}
        <div className={styles.headerButtonContainer}>
          <Space size="large"> 
            <SetLangButton/>
            <Button
              type="primary"
              size="large"
              className={styles.headerButton}
              onClick={() => { window.location.reload(true); }}
            >
              <ReloadOutlined />
            </Button>
            <Button
              type="primary"
              size="large"
              className={styles.headerButton}
              onClick={() => {
                sessionStorage.removeItem("username");
                sessionStorage.removeItem("password");
                sessionStorage.removeItem("restaurant_id");
                sessionStorage.removeItem("ticketrails_roles");
                sessionStorage.removeItem("ticketrails_logged_in");
                history.push("/login");
              }}
            >
              <LogoutOutlined />
            </Button>
          </Space>
        </div>
      </div>
      <ErrorBoundary>
        <div className={styles.content}>{children}</div>
      </ErrorBoundary>
    </div>
  );
};
