import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Alert, Button, Empty, Space, Tag } from "antd";
import { FontSizeOutlined, StarTwoTone } from "@ant-design/icons";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import Page from "./Page";
import ItemCard from "./ItemCard";
import ClearAllButton from "./ClearAllButton";

import { getCredentials, ORDER_TYPE_COLORS, WS_URL } from "./Util";
import styles from "./css/WorkStation.module.css";
import notificationSound from "./notification.mp3";

const notificationAudio = new Audio(notificationSound);

const priorityLabels = {
  10000: <StarTwoTone twoToneColor="#ffc300" className={styles.star} />,
  3000: (
    <Tag color={ORDER_TYPE_COLORS["to go"]} className={styles.toGoTag}>
      To Go
    </Tag>
  ),
  5000: (
    <Tag color={ORDER_TYPE_COLORS["here"]} className={styles.toGoTag}>
      Here
    </Tag>
  ),
};

const websocketDisconnectBanner = (
  <Alert
    message="無法連線"
    description="重新嘗試中..."
    type="error"
    showIcon
    className={styles.websocketDisconnect}
  />
);

const updateItemsByPriority = (oldState, delta) => {
  const newState = { ...oldState };

  Object.entries(delta).forEach(([attrID, attrInfo]) => {
    // Delete wherever this item attribute ID appears across all priorities
    Object.keys(newState).forEach((priority) => {
      newState[priority] = { ...newState[priority] };
      if (newState[priority][attrID]) {
        delete newState[priority][attrID];
      }
    });

    // Update state according to new counts for this item attribute ID
    Object.entries(attrInfo.count_by_priority).forEach(([priority, count]) => {
      if (!newState[priority]) {
        newState[priority] = {};
      }
      let oldestPendingTime = null;
      if (attrInfo.oldest_pending_time_by_priority[priority]) {
        oldestPendingTime = attrInfo.oldest_pending_time_by_priority[priority];
      }

      let newestPendingTime = null;
      if (attrInfo.newest_pending_time_by_priority && attrInfo.newest_pending_time_by_priority[priority]) {
        newestPendingTime = attrInfo.newest_pending_time_by_priority[priority];
      }

      newState[priority][attrID] = {
        item_attributes: attrInfo.item_attributes,
        count: count,
        oldest_pending_time: oldestPendingTime,
        newest_pending_time: newestPendingTime,
      };
    });

    // Delete any priorities with no items
    Object.keys(newState).forEach((priority) => {
      if (Object.keys(newState[priority]).length === 0) {
        delete newState[priority];
      }
    });
  });

  // Play notification sound if new actions showed up for the workstation.
  if (Object.keys(oldState).length === 0 && Object.keys(newState).length > 0) {
    // Play notification sound.
    const Android = window.Android;
    if (Android) {
      Android.playSound();
    } else {
      notificationAudio.play().catch((err) => console.error(err));
    }
  }

  return newState;
};

export default () => {
  const { state } = useLocation();
  if (!state || !state.workStationName || !state.workStationID) {
    window.location.href = "/";
  }
  const wsID = state.workStationID;
  const wsName = state.workStationName;

  const restaurantID = sessionStorage.getItem("restaurant_id");
  if (!restaurantID) {
    const location = useLocation();
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  // Object mapping priority to item attributes to item
  const [itemsByPriority, setItemsByPriority] = useState({});

  // Whether to show the websocket disconnect banner
  const [websocketDisconnect, setWebsocketDisconnect] = useState(false);

  // Whether to show large font. Hard-coded to false for assemble workstation.
  const [showBigFont, setShowBigFont] = useState(wsName !== "組裝");

  useEffect(() => {
    const openWebSocket = () => {
      // Use cookie to pass auth info.
      // https://stackoverflow.com/questions/4361173/http-headers-in-websockets-client-api
      document.cookie = `x-tr-websocket-basic-auth=${getCredentials()}`;
      const url = `${WS_URL}/v1/ws/r/${restaurantID}/workstations/${wsID}/queue`;
      const ws = new W3CWebSocket(url);

      let idlePing = null;

      ws.onopen = () => {
        setWebsocketDisconnect(false);

        // Clear state since the backend only returns the latest state, and our
        // local state could be very stale if we were disconnected.
        setItemsByPriority({});

        // Send a message every 10 seconds to keep the TCP connection busy so it
        // doesn't get killed by the load balancer.
        idlePing = setInterval(() => {
          ws.send("1");
        }, 10 * 1000);
      };

      ws.onclose = () => {
        // Websocket closed.
        // TODO: add better backoff logic to refresh after websocket disconnect
        if (idlePing) {
          clearInterval(idlePing);
        }
        setWebsocketDisconnect(true);

        // Try opening the websocket again.
        setTimeout(() => {
          openWebSocket();
        }, 3000);
      };

      ws.onmessage = (evt) => {
        const message = JSON.parse(evt.data);
        setItemsByPriority((prev) => updateItemsByPriority(prev, message));
      };
    };

    openWebSocket();
  }, [restaurantID, wsID]);

  let itemsByPriorityArr = Object.entries(itemsByPriority);
  itemsByPriorityArr.sort((item1, item2) => {
    const [priority1] = item1;
    const [priority2] = item2;
    return -1 * (priority1 - priority2);
  });
  itemsByPriorityArr = itemsByPriorityArr.map(
    ([priority, unsortedItems], index) => {
      const items = Object.values(unsortedItems);
      items.sort((item1, item2) => {
        if (item1.oldest_pending_time !== item2.oldest_pending_time) {
          return (
            Date.parse(item1.oldest_pending_time) -
            Date.parse(item2.oldest_pending_time)
          );
        }
        return item1.item_attributes.id.localeCompare(item2.item_attributes.id);
      });

      return [priority, items];
    }
  );

  const fontSizeButton = (
    <Button
      type="primary"
      shape="circle"
      size="large"
      icon={<FontSizeOutlined style={{ fontSize: "25px" }} />}
      onClick={() => setShowBigFont((prev) => !prev)}
      style={{ width: "70px", height: "70px" }}
    />
  );

  return (
    <Page title={`工作區: ${wsName}`}>
      {websocketDisconnect && websocketDisconnectBanner}
      {itemsByPriorityArr.length > 0 ? (
        <div>
          <div style={{ float: "right" }}>
            <Space direction="vertical" size="large">
              {fontSizeButton}
              <ClearAllButton
                itemsByPriorityArr={itemsByPriorityArr}
                restaurantID={restaurantID}
                workstationID={wsID}
              />
            </Space>
          </div>
          {itemsByPriorityArr.map(([priority, items], index) => (
            <div
              key={priority}
              className={
                index === itemsByPriorityArr.length - 1
                  ? `${styles.priority} ${styles.lastPriority}`
                  : styles.priority
              }
            >
              <div className={styles.priorityLabel}>
                {priorityLabels[priority]}
              </div>
              <div className={styles.items}>
                {items.map((item) => (
                  <ItemCard
                    key={`${priority}:${item.item_attributes.id}`}
                    priority={priority}
                    item={item}
                    showBigFont={showBigFont}
                    restaurantID={restaurantID}
                    workstationID={wsID}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Empty description="No items" />
      )}
    </Page>
  );
};
