import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Cancel": "Cancel",
      "Clear": "Clear",
      "Done": "Done",

      // Workstation
      "ws_ClearAllItems": "Clear all items?",
    }
  },
  zh: {
    translation: {
      "Cancel": "取消",
      "Clear": "清除",
      "Done": "完成",

      // Workstation
      "ws_ClearAllItems": "你確定要全部清除嗎?",
    }
  },
  es: {
    translation: {
      "Cancel": "Cancelar",
      "Clear": "Limpiar",
      "Done": "Terminado",

      // Workstation
      "ws_ClearAllItems": "¿Limpiar todo?",
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "zh",
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
