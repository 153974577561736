import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import { CLOVER_APP_ID } from "./Util";

export default () => {
  const query = new URLSearchParams(useLocation().search);
  const merchantID = query.get("merchant_id");
  const code = query.get("code");

  if (!merchantID || !code) {
    // TODO send merchant ID and code to backend
    window.location.href = `https://sandbox.dev.clover.com/oauth/authorize?client_id=${CLOVER_APP_ID}`;
  }

  return <Redirect to="/" />;
};
