import React from 'react'; 

import { Divider, Select, Typography } from 'antd';
import moment, { Moment } from 'moment';

import { OrderTable } from './OrderTable';

const { Title } = Typography;

type ReportsViewProps = {
  restaurant_id: string;
}

type TimeRange = {
  startTime: Moment;
  endTime: Moment;
};

const THIS_MONTH_LABEL = 'Current month to date';

function getAllMonths(): Map<string, TimeRange> {
  // Get all months since 2023 Jan as possible choices.
  // 2023 Jan since data that's too old is likely not helpful.
  const choices = new Map<string, TimeRange>();

  const currMonth = moment().startOf('month');
  const historicalMonth = moment('2023/01/01').startOf('month');

  while (currMonth.isAfter(historicalMonth)) {
    const key = historicalMonth.format("YYYY-MM");
    choices.set(key, {
      startTime: historicalMonth.clone(),
      endTime: historicalMonth.clone().endOf('month'),
    });

    historicalMonth.add(1, 'months');
  }

  choices.set(THIS_MONTH_LABEL, {
    startTime: currMonth,
    endTime: currMonth.clone().endOf('month'),
  });

  return choices;
}

export const ReportsView = (props: ReportsViewProps) => {
  const [selectedMonth, setSelectedMonth] = React.useState<string>(THIS_MONTH_LABEL);

  const months = getAllMonths();
  const monthLabels = Array.from(months).
    map(([label, t]) => label).
    sort()

  const timeRange = months.get(selectedMonth);
  if (timeRange == null) {
    // This should never happen.
    throw new Error(`cannot find selected month ${selectedMonth}`);
  }

  return (
    <>
      <Title level={3}>Select month</Title>
      <Select value={selectedMonth} onChange={setSelectedMonth} size="large">
        {
          monthLabels.map(label =>
            <Select.Option value={label}>{label}</Select.Option>
          )
        }
      </Select>
      <Divider /> 
      <OrderTable
        restaurant_id={props.restaurant_id}
        start_time={timeRange.startTime}
        end_time={timeRange.endTime}
      />
    </>
  );
}
