import React from 'react'; 

import {
  Button,
  notification,
  Modal,
  Input,
} from "antd";

import { API_URL } from "../Util";
import { CreateAnnouncementResponse, DeleteAnnouncementResponse } from "../types/api";
import { fetchT } from "../TypedUtil";

const  { TextArea } = Input;

type AnnouncementDeleteButtonProps = {
  restaurantID: string;
  alreadyShutdown: boolean;
  existingShutdownId?: string;
  onSuccess: () => void;
}

export const AnnouncementShutdownButton = (props: AnnouncementDeleteButtonProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
  const [shutDownDetail, setShutdownDetail] = React.useState<string>("Sorry! We are temporarily closed! Please check back later. 不好意思我們目前暫時停止營業.");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCreate = () => {
    setConfirmLoading(true);

    fetchT<CreateAnnouncementResponse>(
      "POST",
      `${API_URL}/v1/r/${props.restaurantID}/shut_down`,
      {
        title: "Temporary Closure / 暫時停止營業",
        content: shutDownDetail,
        annoucement_type: "SHUT_DOWN",
      },
      (result: CreateAnnouncementResponse) => {
        props.onSuccess();

        notification['success']({
          message: 'Success',
          description: `Restaurant shut downed successfully!`,
        });

        setConfirmLoading(false);
        setIsModalOpen(false);
      },
      (err: any) => {
        setConfirmLoading(false);
        setIsModalOpen(false);

        notification['error']({
          message: 'Error',
          description: '發生錯誤',
        });
      },
    );
  }

  const handleDelete = () => {
    setConfirmLoading(true);

    fetchT<DeleteAnnouncementResponse>(
      "POST",
      `${API_URL}/v1/r/${props.restaurantID}/re_open`,
      {},
      (result: DeleteAnnouncementResponse) => {
        props.onSuccess();

        notification['success']({
          message: 'Success',
          description: `Restaurant reopened successfully!`,
        });

        setConfirmLoading(false);
        setIsModalOpen(false);
      },
      (err: any) => {
        setConfirmLoading(false);
        setIsModalOpen(false);

        notification['error']({
          message: 'Error',
          description: '發生錯誤',
        });
      },
    );
  }

  const handleOk = () => {
    if (props.alreadyShutdown) {
      handleDelete();
      return
    }
    handleCreate();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setShutdownDetail(e.target.value);
  }

  return (
    <>
      <Button
        type="primary"
        size="large"
        onClick={showModal}
        style={props.alreadyShutdown ? {border: "1px solid #7C9D1B", backgroundColor: "#7C9D1B"} : {}}
        danger
      >
        {props.alreadyShutdown ? "Open Restaurant" : "Shutdown Restaurant"}
      </Button>
      <p/>
      <Modal
        title={`${props.alreadyShutdown ? "重新" : "暫時停止"}營業`}
        visible={isModalOpen} 
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading: confirmLoading }}
      >
        <p>Are you sure you want to {props.alreadyShutdown ? "Open" : "Shut Down"} the restaurant?</p>
        <p>您確定要{props.alreadyShutdown ? "重新" : "暫時停止"}營業嗎?</p>
        { !props.alreadyShutdown && <TextArea maxLength={4096} onChange={handleTextChange} placeholder={shutDownDetail}/> }
      </Modal>
    </>
  );
}
