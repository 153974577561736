import React, { useEffect, useState } from "react";
import { Button } from "antd";

import { getPrinters, reprintItemLabel } from "./Print";

export default ({
  className,
  style,
  handleError,
  children,
  order,
  item,
}) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  // Get printers from the print agent to see if we can print from this machine.
  useEffect(() => {
    getPrinters((printers, err) => {
      if (err) {
        // Do nothing, do not enable the button.
      } else {
        setDisabled(false);
      }
    });
  }, [order]);

  return (
    <Button
      type="primary"
      disabled={disabled}
      loading={loading}
      className={className}
      style={style}
      size="large"
      onClick={() => {
        setLoading(true);
        reprintItemLabel(
          order,
          item,
          () => {
            setLoading(false);
          },
          (err) => {
            setLoading(false);
            handleError(err);
          }
        )
      }}
    >
      {children}
    </Button>
  );
};
