import React from "react";
import { Button, Result } from "antd";
import * as Sentry from "@sentry/react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    // Log error
    console.error(error);
    console.log(info);

    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Result
          status="500"
          title="500"
          subTitle="抱歉，發生錯誤!"
          extra={
            <Button type="primary" onClick={() => window.location.reload(true)}>
              重新載入
            </Button>
          }
        />
      );
    }
    return this.props.children;
  }
}
