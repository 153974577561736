import React, { useEffect, useRef, useState } from "react";
import { Modal, notification } from "antd";
import { StarOutlined, StarTwoTone } from "@ant-design/icons";
import { Map } from "immutable";

import ButtonWithLoading from "./ButtonWithLoading";
import ItemInfo from "./ItemInfo";
import StationCol from "./StationCol";
import { printOnStartOrder } from "./Print";

import {
  postFetch,
  sortItems,
  hasNewOrders,
  API_URL,
  COLORS,
  STAR_PRIORITY,
} from "./Util";
import "./css/PendingOrderCol.css";
import styles from "./css/PendingOrderCol.module.css";
import notificationSound from "./notification.mp3";

const notificationAudio = new Audio(notificationSound);

export default ({
  orders,
  renderOrder,
  renderOrderTitle,
  renderOrderDetails,
  restaurantID,
  showHeading,
  elemsMaxHeight,
  playSound,
  bgColor,
}) => {
  const [showMoveMenu, setShowMoveMenu] = useState(false);
  const [drawer, setDrawer] = useState(null);
  const prevOrders = useRef([]);

  useEffect(() => {
    if (playSound && hasNewOrders(prevOrders.current, orders)) {
      const Android = window.Android;
      if (Android) {
        Android.playSound();
      } else {
        notificationAudio.play().catch((err) => console.error(err));
      }
    }
    prevOrders.current = orders;
  }, [orders]);

  // Map from order ID to order
  let orderMap = Map();
  orders.forEach((order) => {
    orderMap = orderMap.set(order.id, order);
  });

  return (
    <div className="pendingOrderCol">
      <StationCol
        elems={orders}
        emptyDescription="No orders"
        heading="未開始"
        showHeading={showHeading}
        bgColor={bgColor || "#FAFBB1"}
        elemsMaxHeight={elemsMaxHeight}
        renderLeftButton={(orderID, orderIndex) =>
          orderMap.get(orderID).priority === STAR_PRIORITY ? (
            <StarTwoTone
              className={styles.star}
              twoToneColor="#ffc300"
              onClick={() => {
                postFetch(
                  `${API_URL}/v1/r/${restaurantID}/orders/${orderID}/priority/reset`,
                  {},
                  null,
                  () => {
                    notification['error']({
                      message: "發生錯誤",
                      description: "請稍後再試",
                    });
                  }
                );
              }}
            />
          ) : (
            <StarOutlined
              className={styles.star}
              onClick={() => {
                postFetch(
                  `${API_URL}/v1/r/${restaurantID}/orders/${orderID}/priority`,
                  {
                    priority: STAR_PRIORITY,
                  },
                  null,
                  () => {
                    notification['error']({
                      message: "發生錯誤",
                      description: "請稍後再試",
                    });
                  }
                );
              }}
            />
          )
        }
        renderRightButton={(order, orderIndex) => (
          renderStartButton(restaurantID, order, styles.startButton, {
            height: "inherit",
            fontSize: 24,
          })
        )}
        renderElem={renderOrder}
        renderModal={({
          elem,
          visible,
          onCancel,
          footer,
          style,
          wrapClassName,
        }) => {
          return (
            <Modal
              title={renderOrderTitle ? renderOrderTitle(elem) : null}
              visible={visible}
              onCancel={onCancel}
              footer={footer}
              style={style}
              wrapClassName={wrapClassName}
            >
              {renderOrderDetails(elem)}
            </Modal>
          );
        }}
      />
    </div>
  );
};

const renderStartButton = (restaurantID, order, className, style) => (
  <ButtonWithLoading
    className={styles.startButton}
    style={style}
    postURL={`${API_URL}/v1/r/${restaurantID}/start_orders`}
    postBody={{
      order_ids: [order.id],
    }}
    postHandleResponse={() => {
      // onSuccess
      printOnStartOrder(order, (err) => {
        notification['error']({
          message: "無法列印",
          description: "請稍後再試",
        });
      });
    }}
    postHandleError={() => {
      // onError
      notification['error']({
        message: "發生錯誤",
        description: "請稍後再試",
      });
    }}
  >
    開始
  </ButtonWithLoading>
);
