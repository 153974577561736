import React from 'react'; 

import {
  Collapse,
  Spin,
  Table as AntTable,
} from "antd";

import { NotifyError } from "../NotifyError";
import { API_URL } from "../Util";
import { getFetchT } from "../TypedUtil";
import { RestaurantFeatureFlag } from "../types/model";

import { RestaurantFeatureFlagForm } from "./Form";

const { Panel } = Collapse;

type RestaurantFeatureFlagEditorProps = {
  restaurant_id: string;
}

export const RestaurantFeatureFlagEditor = (props: RestaurantFeatureFlagEditorProps) => {
  const [flags, setFlags] = React.useState<ReadonlyArray<RestaurantFeatureFlag> | undefined>(
    undefined
  );

  const refreshFlags = () => {
    getFetchT<{flags: ReadonlyArray<RestaurantFeatureFlag>}>(
      `${API_URL}/v1/r/${props.restaurant_id}/feature_flags`,
      (result) => setFlags(result.flags),
      (err) => {
        NotifyError("Failed to fetch flags", err);
      },
    );
  };

  // Load current set of announcemets.
  React.useEffect(refreshFlags, [props.restaurant_id]);

  if (flags === undefined) {
    // Still loading
    return (
      <Spin size="large"/>
    );
  }

  const onChange = () => {
    // Set flags to undefined to show loading icon again.
    setFlags(undefined);
    refreshFlags();
  };

  const columns: any = [
    {
      title: "Name",
      dataIndex: "flag_name",
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      render: (text: string, record: RestaurantFeatureFlag) => (
        record.enabled ? "TRUE" : "FALSE"
      ),
    },
  ];

  return (
    <div>
      <Collapse defaultActiveKey={["new", "current"]}>
        <Panel header="Update flag" key="new">
          <RestaurantFeatureFlagForm
            restaurant_id={props.restaurant_id}
            onSuccess={onChange}
          />
        </Panel>
        <Panel header="Current flags" key="current">
          <AntTable
            columns={columns}
            dataSource={flags}
          />
        </Panel>
      </Collapse>
    </div>
  );
}
