import React, { useState } from "react";
import { Button } from "antd";

import { postFetch } from "./Util";

export default ({
  className,
  style,
  disabled,
  postURL,
  postBody,
  postHandleResponse,
  postHandleError,
  children,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      disabled={disabled || loading}
      loading={loading}
      className={className}
      style={style}
      onClick={() => {
        setLoading(true);
        postFetch(
          postURL,
          postBody ? postBody : {},
          (resp, date, etag) => {
            setLoading(false);
            if (postHandleResponse) {
              postHandleResponse(resp, date, etag);
            }
          },
          (err) => {
            setLoading(false);
            if (postHandleError) {
              postHandleError(err);
            }
          }
        );
      }}
    >
      {children}
    </Button>
  );
};
