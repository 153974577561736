// TODO: our typescript or javascript parser does not support public/private.
export class Price {
  // private
  cents: number;

  // private
  constructor(cents: number) {
    if (cents < 0) {
      throw new Error("Price cannot be negative");
    }
    this.cents = cents;
  }

  // public
  static fromCents(cents: number): Price {
    return new Price(cents);
  }

  // public
  static fromString(s: string): Price {
    const parts = s.trim().split('.');
    switch (parts.length) {
      case 1:
        // No decimal place.
        return new Price(parseInt(parts[0]) * 100);
      case 2:
        const whole = parseInt(parts[0]);
        let fraction: number;
        if (parts[1].length === 1) {
          // Pad zero at the end.
          fraction = parseInt(parts[1]) * 10;
        } else {
          fraction = parseInt(parts[1].substring(0, 2));
        }

        return new Price(whole * 100 + fraction);
      default:
        throw new Error(`invalid price: ${s}`);
    }
  }

  // public
  toString(): string {
    const whole = Math.trunc(this.cents / 100);
    const frac = Math.trunc(this.cents % 100);

    let fracString = frac.toString();
    if (frac <= 9) {
      fracString = '0' + fracString;
    }
    return `${whole}.${fracString}`;
  }

  // public
  toCents(): number {
    return this.cents;
  }
}
