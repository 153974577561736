export function isLoggedIn(): boolean {
  return !!sessionStorage.getItem("username") &&
    !!sessionStorage.getItem("password") &&
    !!sessionStorage.getItem("restaurant_id") &&
    !!sessionStorage.getItem("ticketrails_logged_in");
};

export function isAdmin(): boolean {
  const rolesCSV: string | null = sessionStorage.getItem("ticketrails_roles");
  if (rolesCSV) {
    const roles = rolesCSV.split(",");
    for (const r of roles) {
      if (r === "Admin") {
        return true;
      }
    }
  }
  return false;
};
