import React from 'react'; 

import {
  Button,
  Popconfirm,
  notification,
} from "antd";

import { DeleteOutlined } from "@ant-design/icons";

import { API_URL } from "../Util";
import { DeleteItemSchemaResponse } from "../types/api";
import { ItemSchema } from "../types/model";
import { fetchT } from "../TypedUtil";

type ItemSchemaDeleteButtonProps = {
  restaurant_id: string;
  item_schema: ItemSchema;
  on_delete_success: () => void;
}

export const ItemSchemaDeleteButton = (props: ItemSchemaDeleteButtonProps) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    fetchT<DeleteItemSchemaResponse>(
      "DELETE",
      `${API_URL}/v1/r/${props.restaurant_id}/item_schemas/${props.item_schema.id}`,
      {},
      (result: DeleteItemSchemaResponse) => {
        props.on_delete_success();

        notification['success']({
          message: 'Success',
          description: `Deleted ${props.item_schema.name} successfully!`,
        });

        setConfirmLoading(false);
        setVisible(false);
      },
      (err: any) => {
        setConfirmLoading(false);
        setVisible(false);

        notification['error']({
          message: 'Error',
          description: '發生錯誤',
        });
      },
    );
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Popconfirm
      title={`Delete ${props.item_schema.name} ?`}
      visible={visible}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button
        shape="circle"
        icon={<DeleteOutlined />}
        onClick={showPopconfirm}
        style={{ float: "right" }} />
    </Popconfirm>
  );
}
