import React, { useState } from 'react'; 

import {
  Button,
  Col,
  Collapse,
  Divider,
  Row,
  Space,
  Spin,
  Table,
  notification,
  Modal,
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import { API_URL } from "../Util";
import { getFetchT } from "../TypedUtil";
import {
  GetAnnouncementsResponse,
} from "../types/api";
import {
  Announcement,
} from "../types/model";

import { NewAnnouncementForm } from "./Form";
import { AnnouncementDeleteButton } from "./DeleteButton";
import { AnnouncementShutdownButton } from './ShutdownButton';

const { Panel } = Collapse;

type AnnouncementEditorProps = {
  restaurantID: string;
}

export const AnnouncementEditor = (props: AnnouncementEditorProps) => {
  const [announcements, setAnnouncements] = React.useState<Announcement[] | undefined>(undefined);

  const refreshAnnouncements = () => {
    getFetchT<GetAnnouncementsResponse>(
      `${API_URL}/v1/public/r/${props.restaurantID}/announcements`,
      (result: GetAnnouncementsResponse) => {
        setAnnouncements(result.announcements);
      },
      (err) => {
        notification['error']({
          message: 'Error',
          description: '發生錯誤',
        });
      },
    );
  };

  // Load current set of announcemets.
  React.useEffect(refreshAnnouncements, [props.restaurantID]);

  if (announcements === undefined) {
    // Still loading
    return (
      <Spin size="large"/>
    );
  }

  const onChange = () => {
    // Set announcements to undefined to show loading icon again.
    setAnnouncements(undefined);
    refreshAnnouncements();
  };

  const columns: any = [
    {
      title: "Title",
      dataIndex: "title",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      sorter: (a: Announcement, b: Announcement) => {
        return a.title.localeCompare(b.title);
      },
    },
    {
      title: "Content",
      dataIndex: "content",
    },
    {
      title: "Type",
      dataIndex: "annoucement_type",
    },
    {
      render: (text: string, record: Announcement) => (
        <AnnouncementDeleteButton
          restaurantID={props.restaurantID}
          announcement={record}
          onSuccess={onChange}
        />
      ),
    },
  ];

  let alreadyShutdown = false;
  let existingShutdownId = undefined;
  for (const announcement of announcements ?? []) {
    if (announcement.annoucement_type === "SHUT_DOWN") {
      alreadyShutdown = true;
      existingShutdownId = announcement.id;
      break;
    }
  }

  return (
    <div>
      <AnnouncementShutdownButton
        restaurantID={props.restaurantID}
        alreadyShutdown={alreadyShutdown}
        existingShutdownId={existingShutdownId}
        onSuccess={onChange}
      />
      <Collapse defaultActiveKey={["new", "current"]}>
        <Panel header="Create New Announcement" key="new">
          <NewAnnouncementForm
            restaurantID={props.restaurantID}
            onSuccess={onChange}
          />
        </Panel>
        <Panel header="Current Announcements" key="current">
          <Table
            columns={columns}
            dataSource={announcements}
          />
        </Panel>
      </Collapse>
    </div>
  );
}
