import React, { useState } from "react";
import { Alert, Button, Collapse, Divider, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';

import ItemInfo from "./ItemInfo";

import { postFetch, sortItems, API_URL, COLORS } from "./Util";
import { formatAddress } from "./TypedUtil";
import styles from "./css/OrderDetails.module.css";
import PrintPointCardButton from "./PrintPointCardButton";
import PrintNonLabelItemButton from "./PrintNonLabelItemButton";

const { Panel } = Collapse;


export default ({ order, pending, restaurantID }) => {
  const initSelectedDate = order.display_after
    ? moment(order.display_after)
    : null;
  const [selectedDate, setSelectedDate] = useState(initSelectedDate);

  const items = sortItems(order.items);

  return (
    <div className={styles.orderDetails}>
      {(!items || items.length === 0 || !order.pos_order_name) && (
        <Alert
          message="這個單好像不完整。Clover可能有問題。"
          type="warning"
          showIcon
          className={styles.warning}
        />
      )}
      {pending && (
        <div>
          <MuiDateTimePicker
            ampm={false}
            value={selectedDate}
            helperText="請選擇提醒時間"
            format={"ddd, MMM DD, HH:mm"}
            size="large"
            className={styles.datePicker}
            minDate={moment()}
            onChange={(m) => {
              setSelectedDate(m);
            }}
          />
          {selectedDate && (
            <span>
              <Button
                type="primary"
                size="large"
                className={styles.remindButton}
                onClick={() => {
                  postFetch(
                    `${API_URL}/v1/r/${restaurantID}/orders/${order.id}/display_after`,
                    { display_after_time: selectedDate.toISOString() },
                    () => {
                      notification['success']({
                        message: `我會在${selectedDate.format("YYYY-MM-DD HH:mm")}提醒您`,
                      });
                    },
                    () => {
                      notification['error']({
                        message: "發生錯誤",
                        description: "請稍後再試",
                      });
                    }
                  );
                }}
              >
                提醒我
              </Button>
              <Button
                size="large"
                className={styles.remindButton}
                onClick={() => {
                  postFetch(
                    `${API_URL}/v1/r/${restaurantID}/orders/${order.id}/display_after/reset`,
                    {},
                    () => {
                      notification['success']({
                        message: "已取消提醒",
                      });
                      setSelectedDate(null);
                    },
                    () => {
                      notification['error']({
                        message: "發生錯誤",
                        description: "請稍後再試",
                      });
                    }
                  );
                }}
              >
                取消提醒
              </Button>
            </span>
          )}
        </div>
      )}
      {order.notes && (
        <div className={styles.orderNotes}>{order.notes}</div>
      )}
      {
        order.customer != null && (
          <Collapse defaultActiveKey={[]}>
            <Panel header={<span><UserOutlined/> Customer</span>} key="customer-info">
              <div>
                <p>Name: {order.customer.name}</p>
                <p>Phone: {order.customer.phone}</p>
                <p>Email: {order.customer.email}</p>
                { order.customer.address && <p>Address: {formatAddress(order.customer.address)}</p> }
              </div>
            </Panel>
          </Collapse>
        )
      }
      {items &&
        items.map((item, index) => {
          let itemClassName = styles.item;
          let qtyColor = COLORS[item.qty % COLORS.length];
          if (index === items.length - 1) {
            itemClassName = `${itemClassName} ${styles.lastItem}`;
          }
          if (!item.actions) {
            itemClassName = `${itemClassName} ${styles.noActionItem}`;
            qtyColor = "rgba(0,0,0,.25)";
          }
          return (
            <div key={item.id} className={itemClassName}>
              <ItemInfo
                item={item.attributes}
                qty={item.qty}
                qtyColor={qtyColor}
              />
            </div>
          );
        })}
      {
        order.point_credit
          ? (
            order.point_credit.claim_customer_id
            ? (
              <div style={{ color: "red" }}>已領取點數</div>
            )
            : (
              <PrintPointCardButton
                key="print_point_card"
                className={styles.printButton}
                handleError={(err) => {
                  notification['error']({
                    message: "無法列印",
                    description: "請稍後再試",
                  });
                }}
                order={order}
              >
                列印點數
              </PrintPointCardButton>
            )
          )
          : <div/>
      }
      <Divider />
      <div>
        <PrintNonLabelItemButton
          key="print_all_item_in_order"
          className={styles.printButton}
          handleError={(err) => {
            notification['error']({
              message: "無法列印",
              description: "請稍後再試",
            });
          }}
          order={order}
        >
          列印非飲料標籤
        </PrintNonLabelItemButton>
      </div>
    </div>
  );
};
