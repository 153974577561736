import React from "react";

import { QrcodeOutlined } from "@ant-design/icons";

import { API_URL, getCredentials } from "../Util";

type TableQRCodeButtonProps = {
  restaurant_id: string;
  table_id: string;
  table_name: string;
}

export const TableQRCodeButton = (props: TableQRCodeButtonProps) => {
  // TODO(kku): curently we generate QR code for every table whenever we open
  // the page. Change it to only generate on button click.
  const [downloadURL, setDownloadURL] = React.useState<string | undefined>(undefined);

  const download = () => {
    fetch(
      `${API_URL}/v1/r/${props.restaurant_id}/tables/${props.table_id}/qrcode`,
      {
        method: "GET",
        headers: {
          Authorization: `Basic ${getCredentials()}`,
        },
      },
    )
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob], {"type": "image/png"}),
      );
      if (downloadURL !== undefined) {
        window.URL.revokeObjectURL(downloadURL);
      }
      setDownloadURL(url);
    });
  };

  React.useEffect(() => {
    download();
    return () => {
      // Clean up on unmount.
      if (downloadURL !== undefined) {
        window.URL.revokeObjectURL(downloadURL);
      }
    }
  }, [props.table_id]);

  if (downloadURL === undefined) {
    return <div />;
  }

  const filename = `${props.table_name}`.replace(/[^a-z0-9]/gi, '_') + ".png";
  return (
    <a href={downloadURL} download={filename}>
      <QrcodeOutlined />
    </a>
  );
};
