import React from 'react'; 

import {
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
} from "antd";

import { API_URL } from "../Util";
import { fetchT } from "../TypedUtil";
import {
  CreateAnnouncementRequest,
  CreateAnnouncementResponse,
} from "../types/api";

type NewAnnouncementFormProps = {
  restaurantID: string;
  onSuccess: () => void;
}

export const NewAnnouncementForm = (props: NewAnnouncementFormProps) => {
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [form] = Form.useForm();

  const handleSave = (req: CreateAnnouncementRequest) => {
    setSaveLoading(true);

    fetchT<CreateAnnouncementResponse>(
      "POST",
      `${API_URL}/v1/r/${props.restaurantID}/announcements`,
      req,
      (result: CreateAnnouncementResponse) => {
        notification['success']({
          message: 'Success',
          description: `Added new announcement successfully!`,
        });
        form.resetFields();
        setSaveLoading(false);
        props.onSuccess();
      },
      (err: any) => {
        setSaveLoading(false);

        let description: string = "";
        try {
          description = JSON.parse(err)["message"];
        } catch(e) {
          description = "發生錯誤";
        }

        notification['error']({
          message: 'Error',
          description: description,
        });
      },
    );
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 10 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      colon={false}
      onFinish={handleSave}
    >
      <Form.Item
        label={"Title"}
        name="title"
        rules={[
          {
            required: true,
            message: "Title is required.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Content"}
        name="content"
      >
        <Input.TextArea maxLength={4096} />
      </Form.Item>
      <Form.Item
        label={"Type"}
        name="annoucement_type"
        rules={[
          {
            required: true,
            message: "Annoucement type is required.",
          },
        ]}
      >
        <Select>
          <Select.Option value="INFO">Info</Select.Option>
          <Select.Option value="WARNING">Warning</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          block
          htmlType="submit"
          loading={saveLoading}
          disabled={saveLoading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
