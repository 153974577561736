import React from 'react'; 

import {
  Modal,
} from "antd";

import { OrderDetail } from "./OrderDetail";

type OrderDetailModalProps = {
  restaurant_id: string;
  order_id: string;
  title: string;
}

export const OrderDetailModal = (props: OrderDetailModalProps) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <>
      <a onClick={() => setVisible(true)}>{props.title}</a>
      <Modal title={props.title} visible={visible} onOk={() => setVisible(false)} onCancel={() => setVisible(false)}>
        <OrderDetail restaurant_id={props.restaurant_id} order_id={props.order_id} />
      </Modal>
    </>
  );
}
